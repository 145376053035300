import {
  ThreeDots,
  Edit,
  Add,
  Read,
  RemoveMemberIcon,
  RemoveAccessIcon,
  DeleteIcon,
  DownloadFile,
} from "../../Assets/Assets";
import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Empty, Modal, Skeleton, Spin, Table, Tooltip } from "antd";
import NoData from "../../Assets/images/notfound.svg";
import { useNavigate } from "react-router-dom";
import {
  changeRole,
  fetchContainerSharedByMe,
  removeMember,
} from "../../store/slices/shareSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  convertDate,
  convertDateFormat,
  downloadMedia,
  formatString,
  getImage,
  CustomInfiniteScroll,
  //formatName
} from "../../Utils/helpers";
import useLogout from "../../CustomHooks/useLogout";
import { addToBreadcrumb } from "../../store/slices/breadcrumbSlice";
import MediaDisplay from "../../Common/Components/MediaDisplay/MediaDisplay";
import { API_URL, OPERATIONS } from "../../constants";
import PropTypes from "prop-types";
import { INFINITE_SCROLLBAR } from "../../constants";
import toast from "react-hot-toast";
import CommonModal from "../../Common/CommonModal/CommonModal";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
// import "../../Common/Components/CustomSelect/CustomSelect.scss";
import CustomSelect from "../../Common/Components/CustomSelect/CustomSelect";
import { LoadingOutlined } from "@ant-design/icons";
// import { capitalizeFirstLetter } from "../../Utils/helpers";\
import Shildicon from "../../Assets/images/shildicon.png";
import { FILE_NAME } from "../../constants/index.js";
import darkimg from "../../Assets/images/no-data-found-pink.png";
// import { gettheme } from "../../store/slices/themeSlice.js";

function MembersTable({ searchTerm, isMemberGuide, isMemberGuideStore }) {
  let page = useRef(INFINITE_SCROLLBAR.DEFAULT_PAGE);
  let limit = useRef(INFINITE_SCROLLBAR.DEFAULT_LIMIT);

  const handleLogOut = useLogout();
  const dispatch = useDispatch();
  const isLoading = useRef(false);
  const [type, setType] = useState("");

  const [selectedAccessType, setSelectedAccessType] = useState(null);
  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [isDeleteBtnDisable, setDeleteBtnDisable] = useState(false);

  const [prevSelectedAccessType, setPreviousSelectedAccessType] =
    useState(null);
  const [shareableId, setShareableId] = useState(null);
  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [granteeUserId, setGranteeUserId] = useState(null);
  const [downloadIdEncryption, setDownloadingIdEncrytion] = useState("");
  const [downloadFormat, setdownloadFormat] = useState("");
  const [isModalOpene, setIsModalOpene] = useState(false);
  const [removeID, setRemoveId] = useState(null);

  const [isRemoveVisible, setisRemoveVisible] = useState(false);
  const { csrfToken } = useSelector((state) => state.auth);
  const [isCahgeRoleModalVisible, setisCahgeRoleModalVisible] = useState(false);
  const { sharedItems, loading, isMoreData, status } = useSelector(
    (state) => state?.shareing,
  );
  const themeChange = useSelector((state) => state.theme.themeColor);

  useEffect(() => {
    page.current = 1;
    dispatch(
      fetchContainerSharedByMe({
        searchTerm,
        page: INFINITE_SCROLLBAR.DEFAULT_PAGE,
        limit: INFINITE_SCROLLBAR.DEFAULT_LIMIT,
        prevData: null,
        handleLogOut,
        csrfToken,
      }),
    );
  }, [searchTerm]);

  /** Custom Scrollbar */
  CustomInfiniteScroll(
    [isMoreData, sharedItems, isLoading.current, loading],
    "#sharedByMe",
    FILE_NAME.SHARED_WITH_MEMBERS,
    { page, limit, isMoreData, searchTerm, isLoading },
  );

  const navigate = useNavigate();

  const handleClick = (item) => {
    if (item?.shareable_type === "media") {
      if (item?.format === "image/svg+xml") {
        console.log("");
      } else {
        if (
          item?.is_encrypted &&
          item?.format !== "video/mp4" &&
          item?.format !== "image/jpeg" &&
          item?.format !== "audio/mpeg" &&
          item?.format !== "audio/mp3"
        ) {
          setDownloadingIdEncrytion(item?.shareable_id);
          setdownloadFormat(item?.format);
          setIsModalOpene(true);
        } else {
          setIsModalOpen(true);
          setSelectedFile(item);
        }
      }
    } else {
      dispatch(
        addToBreadcrumb({
          id: `/folder-management/${item?.shareable_type}/${item?.shareable_id}`,
          name: item?.shareable_name,
          container_access_level: "all",
        }),
      );
      navigate(
        `/folder-management/${item?.shareable_type}/${item?.shareable_id}`,
      );
    }
  };

  const handleDownload = async (id, format, name = "") => {
    try {
      const downloadUrl = `${API_URL.DOWNLOAD_FILES}${id}/download`;
      await downloadMedia(downloadUrl, format, handleLogOut, name, csrfToken);
    } catch (error) {
      console.error("Error downloading the file:", error);
      toast.error("Download failed!");
    } finally {
      closeModal();
    }
  };

  const getItems = (data) => {
    return [
      data?.shareable_type != "media" && data?.shareable_type != "folder"
        ? {
            label: (
              <span
                className="folderMore"
                onClick={() => handleOperations(data, OPERATIONS.changeAccess)}
              >
                <RemoveAccessIcon />
                Change Access
              </span>
            ),
            key: "0",
          }
        : null,
      {
        label: (
          <span
            className="folderMore"
            onClick={() => handleOperations(data, OPERATIONS.remove)}
          >
            <RemoveMemberIcon /> Remove Member
          </span>
        ),
        key: "1",
      },
    ];
  };

  const closeModal = () => {
    setisCahgeRoleModalVisible(false);
    setisRemoveVisible(false);
    setIsModalOpene(false);
  };

  const removeMemberHandler = async (member_id, shareable_id, removeID) => {
    try {
      setDeleteBtnDisable(true);

      const res = await dispatch(
        removeMember({
          removeID,
          member_id,
          shareable_id,
          handleLogOut,
          csrfToken,
        }),
      );

      if (res?.meta?.requestStatus === "fulfilled") {
        toast.success(res?.payload || "Member removed successfully");
      } else {
        toast.error(
          `Failed to remove member: ${res?.message || "Unexpected error occurred"}`,
        );
      }
      setDeleteBtnDisable(false);
      closeModal();
    } catch (error) {
      console.error("Error removing member:", error);
      toast.error(
        "An error occurred while removing the member. Please try again.",
      );
    }
  };

  const removeMemberHandel = async () => {
    await removeMemberHandler(granteeUserId, shareableId, removeID);
  };

  const changeRoleHandler = async () => {
    try {
      const res = await dispatch(
        changeRole({
          member_id: granteeUserId,
          access_level: selectedAccessType,
          shareable_id: shareableId,
          handleLogOut,
          csrfToken,
        }),
      );

      if (res?.meta?.requestStatus === "fulfilled") {
        toast.success(res?.payload || "Role changed successfully");
        closeModal();
      } else {
        toast.error(
          `Failed to change role: ${res?.message || "Unexpected error occurred"}`,
        );
        closeModal();
      }
    } catch (error) {
      console.error("Error changing role:", error);
      toast.error(
        "An error occurred while changing the role. Please try again.",
      );
      closeModal();
    }
  };

  const handleOperations = (data, action) => {
    switch (action) {
      case OPERATIONS.open:
        handleClick(data);
        break;

      case OPERATIONS.remove:
        setType(data?.shareable_type);
        setisRemoveVisible(true);
        setRemoveId(data?.id);
        setShareableId(data?.shareable_id);
        setGranteeUserId(data?.grantee_user_id);

        break;
      case OPERATIONS.changeAccess:
        setGranteeUserId(data?.grantee_user_id);
        setShareableId(data?.shareable_id);
        setPreviousSelectedAccessType(data?.access_level);
        setSelectedAccessType(data?.access_level);
        setSelectedAssetType(data?.shareable_type);
        setisCahgeRoleModalVisible(true);
        break;

      default:
        break;
    }
  };

  const data = Array.isArray(sharedItems)
    ? sharedItems.map((item, index) => {
        const path =
          item.shareable_type === "container"
            ? `/folder-management/container/${item.shareable_id}`
            : item.shareable_type === "folder"
              ? `/folder-management/folder/${item.shareable_id}`
              : "";

        return {
          id: item?.id,
          shareable_id: item.shareable_id,
          shareable_name: item?.shareable_name,
          ipfs_url: item?.ipfs_url,
          is_encrypted: item?.is_encrypted || false,
          format: item?.format,
          access_level: (
            <div className="accesLevel">
              {item.access_level === "manage" ? (
                <Tooltip title="Manage" placement="bottomLeft">
                  <span className="iconCenter">
                    <Edit />
                  </span>
                </Tooltip>
              ) : item.access_level === "read" ? (
                <Tooltip title="View" placement="topLeft">
                  <span className="iconCenter">
                    <Read />
                  </span>
                </Tooltip>
              ) : item.access_level === "write" ? (
                <Tooltip title="Add" placement="topLeft">
                  <span className="iconCenter">
                    <Add />
                  </span>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          ),
          shareable_type: item.shareable_type,
          sendby: item?.name,
          // name_data:item?.name,
          name:
            item?.name?.length < 20 ? (
              <p className="lineHeight20">{item?.name}</p>
            ) : (
              <Tooltip key={item.id} placement="topLeft" title={item?.name}>
                <p className="ellipsis lineHeight20">
                  {formatString(item?.name, 6, 6)}
                </p>
              </Tooltip>
            ),

          container: (
            <span
              className="container-ellipsis"
              key={`upload${item?.id || index + 1}`}
              onClick={() => {
                if (item.shareable_type !== "media") {
                  navigate(path);
                }
              }}
              style={{
                cursor: item.shareable_type !== "media" ? "pointer" : "default",
              }}
            >
              <span>
                {/* {item?.shareable_type === "container" ? (
                  <img width={20} src={Box} alt="" key={`upload${index + 1}`} />
                ) : item.shareable_type === "folder" ? (
                  <FolderImg />
                ) : (
                  <FileMangeIcon key={`upload${index + 1}`} />
                )}
                <span>{item.shareable_name}</span> */}
                <img
                  src={
                    item?.is_encrypted
                      ? Shildicon
                      : getImage(
                          item?.format,
                          item?.ipfs_url,
                          item?.shareable_id,
                          item?.shareable_type,
                        )
                  }
                />
                {item?.shareable_name.length < 20 ? (
                  item?.shareable_name
                ) : (
                  <Tooltip
                    key={item.id}
                    placement="topLeft"
                    title={item?.shareable_name}
                  >
                    <span>{formatString(item?.shareable_name, 6, 6)}</span>
                  </Tooltip>
                )}
              </span>
            </span>
          ),

          wallet_address: (
            <Tooltip
              key={item.id}
              placement="topLeft"
              title={item?.wallet_address}
            >
              {formatString(item?.wallet_address, 6, 6)}
            </Tooltip>
          ),
          email: <p className="ellipsis">{item.email || ""}</p>,
          sharedDate: convertDate(item.access_granted_date) || "",
          blankIcon: (
            <Dropdown menu={{ items: getItems(item) }} trigger={["click"]}>
              <a className="threeDot" onClick={(e) => e.preventDefault()}>
                <ThreeDots />
              </a>
            </Dropdown>
          ),
        };
      })
    : [];

  const columns = [
    {
      key: "container",
      title: ["Name"],
      dataIndex: "container",
      onCell: (record) => ({
        onClick: () => handleClick(record),
      }),
      sorter: (a, b) => {
        const nameA = a.shareable_name;
        const nameB = b.shareable_name;
        return nameA.localeCompare(nameB);
      },
    },
    {
      key: "name",
      title: ["Username"],
      dataIndex: "name",
      onCell: (record) => ({
        onClick: () => handleClick(record),
      }),
      sorter: (a, b) => {
        const nameA = a.sendby || "";
        const nameB = b.sendby || "";
        return nameA.localeCompare(nameB);
      },
    },
    {
      key: "wallet_address",
      title: ["Wallet Address"],
      dataIndex: "wallet_address",
      onCell: (record) => ({
        onClick: () => handleClick(record),
      }),
    },
    {
      key: "sharedDate",
      title: ["Shared Date"],
      dataIndex: "sharedDate",
      onCell: (record) => ({
        onClick: () => handleClick(record),
      }),
      sorter: (a, b) => {
        return (
          new Date(convertDateFormat(a.sharedDate)) -
          new Date(convertDateFormat(b.sharedDate))
        );
      },
    },
    {
      key: "access_level",
      title: ["Access"],
      dataIndex: "access_level",
      onCell: (record) => ({
        onClick: () => handleClick(record),
      }),
    },
    {
      key: "blankIcon",
      title: <div className="">Action</div>,
      dataIndex: "blankIcon",
    },
  ];

  const handleChangeShare = (value) => {
    setSelectedAccessType(value);
  };

  return (
    <div>
      {ismodalOpen && (
        <MediaDisplay
          selectedMedia={selectedFile}
          setModalOpen={setIsModalOpen}
        />
      )}
      {!loading ? (
        <Table
          id="sharedByMe"
          columns={columns}
          dataSource={data}
          locale={{
            emptyText: (
              <Empty
                image={
                  <img
                    src={themeChange === "dark" ? darkimg : NoData}
                    alt="empty"
                  />
                }
                description="No Members Added"
              />
            ),
          }}
          // scroll={{ y: 525, x: "auto" }}
          showSorterTooltip={{
            target: "sorter-icon",
          }}
          pagination={false}
          className="commontable"
          // overflow="auto"
        />
      ) : (
        <div className="tableSkeleton" data-testid="skeleton-container">
          {(isMemberGuide || isMemberGuideStore) && (
            <Skeleton active paragraph={{ rows: 5 }} />
          )}
        </div>
      )}

      <CommonModal
        className="changeAccess"
        visible={isCahgeRoleModalVisible}
        title="Change Access"
        customBtutton={true}
        BtnText={
          status === "changing" ? (
            <Spin indicator={<LoadingOutlined spin />} />
          ) : (
            "Continue"
          )
        }
        onClick={changeRoleHandler}
        btnDisabled={
          prevSelectedAccessType === selectedAccessType || status === "changing"
        }
        icon={<RemoveAccessIcon />}
        content={
          <div>
            <CustomSelect
              regularInput
              label
              labelText=""
              defaultValue={selectedAccessType}
              value={selectedAccessType}
              description={
                selectedAccessType === "read"
                  ? `The user can only read the files in the  ${selectedAssetType ?? "container"}.`
                  : selectedAccessType === "manage"
                    ? `User can fully manage the data in your ${selectedAssetType ?? "container"}.They can view, upload and delete files in your ${selectedAssetType ?? "container"}.`
                    : `The user can only add new files to the ${selectedAssetType ?? "container"} they cannot change or delete existing files.`
              }
              options={[
                {
                  value: "read",
                  label: "View Only",
                },
                {
                  value: "write",
                  label: "Write",
                },
                {
                  value: "manage",
                  label: "Manage",
                },
              ]}
              onChange={(value) => handleChangeShare(value)}
            />
          </div>
        }
        onOk={closeModal}
        onCancel={closeModal}
      />
      <CommonModal
        className="confirmationRemoveModal"
        visible={isRemoveVisible}
        BtnText="Continue"
        icon={<DeleteIcon />}
        title={`Remove Member`}
        content={
          <div className="confirmationRemoveModal_inner">
            <h4>
              Are you sure you want to remove access of the user from shared{" "}
              {type}? He/She will no longer be able to view this {type}.
            </h4>
            <div className="confirmationRemoveModal_btns">
              <ButtonCustom
                onClick={() => setisRemoveVisible(false)}
                borderBtn
                label="Cancel"
              />
              <ButtonCustom
                onClick={removeMemberHandel}
                regularBtn
                // label="Yes"

                className={isDeleteBtnDisable ? "disableBtn" : ""}
                disabled={isDeleteBtnDisable}
                label={
                  isDeleteBtnDisable ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    "Remove"
                  )
                }
              />
            </div>
          </div>
        }
        onOk={closeModal}
        onCancel={closeModal}
      />
      <Modal
        className="modal_lock"
        open={isModalOpene}
        onOk={closeModal}
        onCancel={closeModal}
      >
        <div className="modal_main">
          <div className="imgdive">
            <img src={Shildicon} alt="Shield Icon" />
          </div>
          <h4>This file is encrypted</h4>
          <p>To access the file, you must first download it.</p>
          <button
            className="customButton customButton-smallBtn modalbtn"
            id="create-file-button"
            onClick={() => handleDownload(downloadIdEncryption, downloadFormat)}
          >
            <p>Download</p>
            <span className="customButton-icon download">
              <DownloadFile />
            </span>
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default MembersTable;
MembersTable.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  isMemberGuide: PropTypes.bool,
  isMemberGuideStore: PropTypes.bool,
};
