import {
  CopyIcon,
  BellIcon,
  ContactUs,
  // CrossIcon2,
  SignOutIcon,
  BillingIcon,
  SettingsIcon,
  DropdownIcon,
  UserProfileIcon,
  MoonIcon,
  SunIcon,
} from "../../../Assets/Assets";
import {
  fetchNotifications,
  updateNotificationStatus,
} from "../../../store/slices/notificationSlice";
import {
  addToBreadcrumb,
  resetBreadcrumb,
} from "../../../store/slices/breadcrumbSlice";
import {
  setPlanId,
  setPlanType,
  setNextPlan,
  setPlanExpiry,
  setPaymentPlan,
  setPaymentProcessModal,
} from "../../../store/slices/userSlice";
import {
  copy,
  convertDate,
  formatString,
  launchConfetti,
} from "../../../Utils/helpers";
import "./Header.scss";
import toast from "react-hot-toast";
import NoData from "../../noData/NoData";
import { env } from "../../../constants/env";
import { Drawer, Dropdown, Modal } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { AppContext } from "../../../store/Context";
import httpRequest from "../../../Utils/httpsRequest";
import { useDispatch, useSelector } from "react-redux";
import useLogout from "../../../CustomHooks/useLogout";
import HammerMenu from "../SidebarDashboard/HammerMenu";
import { Path } from "../../../Routing/Constant/RoutePaths";
import React, { useContext, useEffect, useState } from "react";
import useCurrentWidth from "../../customHooks/useCurrentWidth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SidebarDashboard from "../SidebarDashboard/SidebarDashboard";
import { setMainLoading } from "../../../store/slices/uiSettingSlice";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../../constants";
import localStorageUtil from "../../../Utils/localStorage";
import { gettheme, setTheme } from "../../../store/slices/themeSlice";

function Header() {
  const width = useCurrentWidth();
  const handleLogout = useLogout();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname;
  const [showConfetti, setConfetti] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userId } = useSelector((state) => state.user);
  const { clearUploadFileQueue } = useContext(AppContext);
  const { csrfToken } = useSelector((state) => state.auth);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { walletAddress } = useSelector((state) => state.auth);
  const { planType, nextPlan, planId } = useSelector((state) => state.user);
  const { updateNotification } = useSelector((state) => state.notifications);

  //todo
  if (planType || nextPlan) {
    console.log("df");
  } else {
    navigate(Path.ACCOUNT_TYPE);
  }

  useEffect(() => {
    if (showConfetti) {
      launchConfetti();
      setConfetti(false);
    }
  }, [showConfetti]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await httpRequest(
          API_URL.PAID_PLAN + "/" + planId,
          HTTP_METHOD.GET,
          null,
          {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          null,
          handleLogout,
        );

        if (response.code === HTTP_STATUS_CODE.OK) {
          const paymentStatus = response?.data?.payment_status ?? "";

          if (paymentStatus === "pending") {
            dispatch(setPaymentPlan(response?.data?.plan_name));
            setTimeout(fetchData, 10000);
          } else {
            dispatch(setPlanId(null));
            dispatch(setNextPlan(null));
            dispatch(setPaymentPlan(null));
          }

          if (paymentStatus === "failed") {
            toast.error("Your payment has been failed");
            dispatch(setPaymentProcessModal(false));
          }

          if (paymentStatus === "success") {
            dispatch(setPlanType(nextPlan));
            dispatch(setPlanExpiry(null));
            dispatch(setPaymentProcessModal(false));
            toast.success("Your payment has been successful");
            setConfetti(true);
          }
        }
      } catch (error) {
        console.error("Error fetching data!");
      }
    };
    if (nextPlan) {
      fetchData();
    }
  }, [nextPlan]);
  const getPlan = async () => {
    try {
      const response = await httpRequest(
        API_URL.GET_PLAN,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogout,
      );
      if (response?.code === HTTP_STATUS_CODE.OK) {
        dispatch(setPlanType(response?.data?.plan_name));
      }
    } catch (error) {
      console.log(" error:", error);
    }
  };
  useEffect(() => {
    dispatch(setMainLoading(false));
    dispatch(fetchNotifications({ userId, csrfToken, handleLogout }));
    getPlan();
  }, []);

  const unreadNotifications =
    updateNotification &&
    updateNotification.filter(
      (notification) => notification.status === "unread",
    );

  const handleAudioPlayback = () => {
    const audio = new Audio("/audio/notification.wav");
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };

  useEffect(() => {
    if (width <= 767) {
      setIsModalOpen(false);
    }
  }, [width]);

  try {
    useEffect(() => {
      const socketConnection = new WebSocket(
        `${env.websocketBackendUrl}${API_URL.WS_NOTIFICATION}`,
      );
      socketConnection.withCredentials = true;
      socketConnection.addEventListener("message", (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data?.notification) {
            toast(data?.notification?.message, { icon: "👏" });

            if (document.visibilityState === "visible") {
              handleAudioPlayback();
            }
            dispatch(fetchNotifications({ userId, csrfToken, handleLogout }));
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      });

      socketConnection.addEventListener("open", () => {
        console.log("WebSocket connection established");
      });

      socketConnection.addEventListener("error", (error) => {
        console.error("WebSocket connection error:", error);
      });

      socketConnection.addEventListener("close", () => {
        console.log("WebSocket connection closed");
      });

      return () => {
        socketConnection.close();
      };
    }, []);
  } catch (error) {
    console.error("Error initializing WebSocket connection:", error);
  }

  const notificationStatusUpdate = async (notificationId) => {
    try {
      await dispatch(
        updateNotificationStatus({ notificationId, csrfToken, handleLogout }),
      );
    } catch (error) {
      console.log("Error while deleting notification: ", error);
    }
  };

  const showModal = () => {
    if (width > 767) {
      setIsModalOpen(true);
    } else {
      navigate(Path.NOTIFICATION);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSignOut = async () => {
    try {
      const response = await httpRequest(
        API_URL.LOGOUT,
        HTTP_METHOD.DELETE,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogout,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        handleLogout();
        localStorageUtil.clear();
        clearUploadFileQueue();
      }
    } catch (error) {
      console.log("Error while signing out: ", error);
    }
  };

  const items = [
    {
      label: (
        <Link to={Path.BILLING_INFORMATION}>
          <BillingIcon /> <span>Billing</span>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link to={Path.SETTINGS}>
          <SettingsIcon size={16} /> <span>Settings</span>
        </Link>
      ),
      key: "1",
    },

    {
      label: (
        <Link to={Path.CONTACTUS}>
          <ContactUs />
          <span>Contact Us</span>
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <div className="signOut" onClick={handleSignOut}>
          <SignOutIcon />
          <span>Sign Out</span>
        </div>
      ),
      key: "3",
    },
  ];

  const handleNavigation = (item) => {
    notificationStatusUpdate(item?.id);
    if (item?.shareable_type !== "media") {
      dispatch(resetBreadcrumb());
      dispatch(
        addToBreadcrumb({
          id: `folder-management/${item?.shareable_type}/${item?.shareable_id}`,
          name: item?.shareable_name,
          container_access_level: item?.access_level,
        }),
      );
      setIsModalOpen(false);
      navigate(
        `folder-management/${item?.shareable_type}/${item?.shareable_id}`,
      );
    }
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const [themeChange, setThemeChange] = useState(useSelector(gettheme));

  const handleToggle = () => {
    const newTheme = themeChange === "light" ? "dark" : "light";
    setThemeChange(newTheme);
    dispatch(setTheme(newTheme));
  };

  useEffect(() => {
    handlerToChangeTheme(themeChange);
  }, [themeChange]);

  const handlerToChangeTheme = (themeChange) => {
    if (themeChange === "light") {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    } else {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    }
  };

  return (
    <div className={`header `}>
      <div className="header_left">
        {" "}
        {width < 992 ? (
          <>
            <HammerMenu onClick={showDrawer} onClose={onClose} open />
            <Drawer
              placement="left"
              closable={true}
              onClose={onClose}
              open={drawerVisible}
            >
              <SidebarDashboard
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                setDrawerState={onClose}
              />
            </Drawer>
          </>
        ) : null}
        <h3>
          {currentLocation === Path.DASHBOARD && "Dashboard"}
          {currentLocation === Path.FILE_MANAGEMENT && "File Management"}
          {currentLocation === Path.ACCESSKEY && "Access Key"}
          {currentLocation === Path.MEMBERS && "Members"}
          {currentLocation === Path.DAO && "DAO"}
          {currentLocation === Path.SETTINGS && "Account Settings"}
          {currentLocation === Path.BILLING_INFORMATION && "Account Settings"}
          {currentLocation === Path.NOTIFICATION && "Notifications"}
          {currentLocation === Path.CONTACTUS && "Contact Us"}
          {currentLocation === Path.API_KEY && "API Key Generation"}
        </h3>
      </div>
      <div className="header_right">
        {/* <Switch
          defaultChecked
          onChange={(event) => {
            handleToggle(event);
          }}
        /> */}

        <div className="header_right_bellicon">
          <div className="bell-container" onClick={showModal}>
            <BellIcon />
            {unreadNotifications && unreadNotifications.length > 0 ? (
              <div className="red-dot"></div>
            ) : (
              <></>
            )}
          </div>
          {walletAddress && (
            <div className="header_right_hash">
              <span
                onClick={() => {
                  copy(walletAddress);
                }}
              >
                <CopyIcon />
              </span>
              <p>{formatString(walletAddress)}</p>
            </div>
          )}
        </div>
        <div className="theme-toggle " onClick={handleToggle}>
          {themeChange === "light" ? <MoonIcon /> : <SunIcon />}
        </div>
        <Dropdown
          className="menuDropdown"
          overlayClassName="userDropdown"
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <div className="user_icon">
              <UserProfileIcon />
            </div>
            <DropdownIcon />
          </a>
        </Dropdown>
      </div>
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={null}
        closable={false}
        className="common-modal notificationModal notification"
      >
        <div className="modal-header notificationModal_head">
          <div className="notificationModal_head_left">
            <div className="icon">
              <BellIcon />
            </div>
            <div className="title">Notifications</div>
          </div>
          <div className="notificationModal_head_right">
            <Link to={Path.NOTIFICATION}>
              <button onClick={handleCancel}>View All</button>
            </Link>
          </div>
        </div>
        <div className="modal-content notificationModal_content">
          {updateNotification && updateNotification.length > 0 ? (
            updateNotification
              .filter((item) => item?.status === "unread")
              .slice(0, 5).length > 0 ? (
              updateNotification
                .filter((item) => item?.status === "unread")
                .slice(0, 5)
                .map((item, index) => (
                  <div key={index} className="notification-container">
                    <div
                      className="notificationModal_content_inner"
                      onClick={() => handleNavigation(item)}
                    >
                      <BellIcon />
                      <div>
                        {item?.content?.length > 45 ? (
                          <h4>{formatString(item?.content, 45, 0)}</h4>
                        ) : (
                          <h4>{item?.content}</h4>
                        )}
                        <p>{convertDate(item?.created_at)}</p>
                      </div>
                    </div>
                    <div className="profileOption">
                      {(item?.shareable_type === "container" ||
                        item?.shareable_type === "folder") && (
                        <div onClick={() => handleNavigation(item)}>
                          <ExportOutlined />
                        </div>
                      )}
                      <div
                        className="notification"
                        onClick={() => notificationStatusUpdate(item?.id)}
                      ></div>
                    </div>
                  </div>
                ))
            ) : (
              <NoData
                className="no-notifications"
                message="No notifications found"
              />
            )
          ) : (
            <NoData
              className="no-notifications"
              message="No notifications found"
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Header;
