/* eslint-disable no-undef */
import axios from "axios";
import { env } from "../constants/env";
import { HTTP_HEADER, HTTP_STATUS_CODE } from "../constants";
import localStorageUtil from "./localStorage";
// import toast from "react-hot-toast";

// /**
//  * Axios-based utility to handle HTTP requests in a centralized manner.
//  * - Uses a base URL from environment variables for modularity.
//  * - Configures default headers and enables credentials support for cross-origin requests.
//  * - Accepts dynamic URL, HTTP method, and optional data for making API requests.
//  * - Handles errors gracefully, logging them for debugging, and returns either response data or error.
//  */

const axiosApi = axios.create({
  baseURL: env?.backendUrl,
  withCredentials: true,
});

const httpRequest = async (
  url,
  method,
  data = null,
  headers = HTTP_HEADER.default,
  responseType = null,
  logout = null,
  signal = null,
) => {
  try {
    headers = {
      ...headers,
    };

    let options = {
      url,
      method,
      data,
      ...(responseType && { responseType }),
      headers,
    };
    if (signal) {
      options = {
        url,
        method,
        data,
        ...(responseType && { responseType }),
        headers,
        signal,
      };
    }

    const response = await axiosApi(options);
    localStorageUtil.setItem("csrfToken", response.headers.get("x-csrf-token"));

    return response.data;
  } catch (error) {
    if (
      error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED &&
      error?.response?.data?.message?.includes("User not authenticated")
    ) {
      logout();
    }

    return {
      error: error.response ? error.response.data : "An error occurred",
      code: error?.response?.status,
    };
  }
};

export default httpRequest;
