import React, { useEffect, useState, useRef } from "react";
import { Empty, Modal, Skeleton, Table, Tooltip } from "antd";
import {
  ThreeDots,
  FolderIcon,
  AddContainerICon,
  FolderICon,
  Add,
  Read,
  Edit,
  DownloadFile,
} from "../../Assets/Assets";
import { Dropdown } from "antd";

import NoData from "../../Assets/images/notfound.svg";
import darkimg from "../../Assets/images/no-data-found-pink.png";
import httpRequest from "../../Utils/httpsRequest";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../constants";

import toast from "react-hot-toast";
import "./ContainersSharedWithMe.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { INFINITE_SCROLLBAR } from "../../constants";
import { fetchContainerSharedWithMe } from "../../store/slices/shareSlice";
import useLogout from "../../CustomHooks/useLogout";
import {
  convertDate,
  convertDateFormat,
  formatStorageSize,
  formatString,
  getImage,
  capitalizeFirstLetter,
  downloadMedia,
  CustomInfiniteScroll,
} from "../../Utils/helpers";
import { OPERATIONS } from "../../constants";
import { addToBreadcrumb } from "../../store/slices/breadcrumbSlice";
import CommonModal from "../../Common/CommonModal/CommonModal";
import MediaDisplay from "../../Common/Components/MediaDisplay/MediaDisplay";
import PropTypes from "prop-types";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Shildicon from "../../Assets/images/shildicon.png";
import { FILE_NAME } from "../../constants/index.js";

function SharedWithMeTable(searchTerm) {
  let page = useRef(INFINITE_SCROLLBAR.DEFAULT_PAGE);
  let limit = useRef(INFINITE_SCROLLBAR.DEFAULT_LIMIT);
  const handleLogOut = useLogout();
  const [itemDetails, setItemDetails] = useState("");
  const [detailView, setDetailView] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [downloadIdEncryption, setDownloadingIdEncrytion] = useState("");
  const [downloadFormat, setdownloadFormat] = useState("");
  const [isModalOpeneEncyption, setIsModalOpeneEncyption] = useState(false);

  const [ismodalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { sharedItems, loading, isMoreData } = useSelector(
    (state) => state?.shareing,
  );
  const { csrfToken } = useSelector((state) => state.auth);
  useEffect(() => {
    page.current = INFINITE_SCROLLBAR.DEFAULT_PAGE;
    dispatch(
      fetchContainerSharedWithMe({
        searchTerm,
        page: INFINITE_SCROLLBAR.DEFAULT_PAGE,
        limit: INFINITE_SCROLLBAR.DEFAULT_LIMIT,
        prevData: null,
        handleLogOut,
        csrfToken,
      }),
    );
  }, [searchTerm]);

  /** Custom Scrollbar */
  const isLoading = useRef(false);

  CustomInfiniteScroll(
    [isMoreData, sharedItems, isLoading.current, loading],
    "#sharedWithMe",
    FILE_NAME.SHARED_WITH_ME,
    { page, limit, isMoreData, searchTerm, isLoading },
  );

  const closeModal = () => {
    setDetailView(false);
    setIsModalOpeneEncyption(false);
  };

  const navigate = useNavigate();

  const handleClick = (item) => {
    if (item?.shareable_type === "media") {
      if (item?.format === "image/svg+xml") {
        console.log("Svg");
      } else {
        if (
          item?.is_encrypted &&
          item?.format !== "video/mp4" &&
          item?.format !== "image/jpeg" &&
          item?.format !== "image/png" &&
          item?.format !== "audio/mpeg" &&
          item?.format !== "audio/mp3"
        ) {
          setDownloadingIdEncrytion(item?.shareable_id);
          setdownloadFormat(item?.format);
          setIsModalOpeneEncyption(true);
        } else {
          setIsModalOpen(true);
          setSelectedFile(item);
        }
      }
    } else if (item?.shareable_type === "folder") {
      dispatch(
        addToBreadcrumb({
          id: `/folder-management/folder/${item?.shareable_id}`,
          name: item?.shareable_name,
          container_access_level: item?.access_level,
        }),
      );
      navigate(`/folder-management/folder/${item?.shareable_id}`);
    } else {
      dispatch(
        addToBreadcrumb({
          id: `/folder-management/container/${item?.shareable_id}`,
          name: item?.shareable_name,
          container_access_level: item?.access_level,
        }),
      );
      navigate(`/folder-management/container/${item?.shareable_id}`);
    }
  };
  const handleDownload = async (id, format, name = "") => {
    try {
      const downloadUrl = `${API_URL.DOWNLOAD_FILES}${id}/download`;
      await downloadMedia(downloadUrl, format, handleLogOut, name, csrfToken);
    } catch (error) {
      console.error("Error downloading the file:", error);
      toast.error("Download failed!");
    } finally {
      closeModal();
    }
  };

  const columns = [
    {
      title: ["Name"],
      dataIndex: "name",
      key: "name",
      onCell: (record) => ({
        onClick: () => handleClick(record),
      }),
      sorter: (a, b) => {
        const nameA = a.shareable_name || "";
        const nameB = b.shareable_name || "";

        const comparisonResult = nameA.localeCompare(nameB);

        return comparisonResult;
      },
      width: "16%",
    },
    {
      title: ["Email"],
      dataIndex: "email",
      key: "email",
      onCell: (record) => ({
        onClick: () => handleClick(record),
      }),

      width: "16%",
    },
    {
      title: ["Wallet Address"],
      dataIndex: "wallet_address",
      key: "wallet_address",
      onCell: (record) => ({
        onClick: () => handleClick(record),
      }),

      width: "16%",
    },

    {
      title: ["Shared Date"],
      dataIndex: "sharedDate",
      key: "sharedDate",
      onCell: (record) => ({
        onClick: () => handleClick(record),
      }),
      sorter: (a, b) => {
        return (
          new Date(convertDateFormat(a.sharedDate)) -
          new Date(convertDateFormat(b.sharedDate))
        );
      },
      width: "16%",
    },
    {
      title: <div className="">Access</div>,
      dataIndex: "access_levels",
      key: "access_levels",
      onCell: (record) => ({
        onClick: () => handleClick(record),
      }),
      width: "16%",
    },
    {
      title: <div className="">Action</div>,
      dataIndex: "blankIcon",
      key: "blankIcon",
    },
  ];

  const getItems = (data) => {
    if (data?.shareable_type === "folder") {
      return [
        {
          label: (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, OPERATIONS.open)}
            >
              <FolderIcon /> Open {data?.shareable_type}
            </span>
          ),
          key: "0",
        },

        {
          label: (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, OPERATIONS.details)}
            >
              <FolderICon /> {capitalizeFirstLetter(data?.shareable_type)}{" "}
              Details
            </span>
          ),
          key: "1",
        },
      ];
    } else if (data?.shareable_type === "container") {
      return [
        {
          label: (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, OPERATIONS.open)}
            >
              <FolderIcon /> Open {data?.shareable_type}
            </span>
          ),
          key: "0",
        },

        {
          label: (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, OPERATIONS.details)}
            >
              <FontAwesomeIcon icon={faCircleInfo} className="item_fa" />
              {capitalizeFirstLetter(data?.shareable_type)} Details
            </span>
          ),
          key: "1",
        },
      ];
    } else if (data?.shareable_type === "media") {
      let arr = [
        {
          label: (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, OPERATIONS.open)}
            >
              <FolderIcon /> Open {data?.shareable_type}
            </span>
          ),
          key: "0",
        },

        {
          label: (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, OPERATIONS.details)}
            >
              <FontAwesomeIcon icon={faCircleInfo} className="" />
              {capitalizeFirstLetter(data?.shareable_type)} Details
            </span>
          ),
          key: "1",
        },
      ];
      if (data.access_level == "manage") {
        arr.push({
          label: (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, OPERATIONS.delete)}
            >
              <FolderIcon /> Delete {data?.shareable_type}
            </span>
          ),
          key: "2",
        });
      }
      return arr;
    }
  };

  const deleteContainer = async (id) => {
    try {
      const response = await httpRequest(
        `${API_URL.DELETE_MEDIA}${id}`,
        HTTP_METHOD.DELETE,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        dispatch(
          fetchContainerSharedWithMe({
            searchTerm,
            page: INFINITE_SCROLLBAR.DEFAULT_PAGE,
            limit: INFINITE_SCROLLBAR.DEFAULT_LIMIT,
            prevData: null,
            handleLogOut,
            csrfToken,
          }),
        );
        toast.success(response?.message || "Successfully  deleted container");
      } else {
        toast.error(response?.message || "Something went wrong");
      }
    } catch (error) {
      console.log("error while deleting container : ", error);
    }
  };

  const handleOperations = (data, action) => {
    switch (action) {
      case OPERATIONS.open:
        handleClick(data);
        break;
      case OPERATIONS.details:
        setItemDetails(data);
        setDetailView(true);
        break;
      case OPERATIONS.delete:
        deleteContainer(data?.shareable_id);
        break;

      default:
        break;
    }
  };

  const data = Array.isArray(sharedItems)
    ? sharedItems.map((item, index) => {
        const path =
          item?.shareable_type === "container"
            ? `/folder-management/container/${item.shareable_id}`
            : item?.shareable_type === "folder"
              ? `/folder-management/folder/${item.shareable_id}`
              : "";

        return {
          key: item?.id || index + 1,
          shareable_id: item?.shareable_id,
          shareable_name: item?.shareable_name,
          access_level: item?.access_level,
          ipfs_url: item?.ipfs_url,
          is_encrypted: item?.is_encrypted || false,
          format: item?.format,
          shareable_type: item?.shareable_type,
          access_levels:
            item?.access_level === "manage" ? (
              <Tooltip title="Manage" placement="topLeft">
                <span>
                  <Edit />
                </span>
              </Tooltip>
            ) : item?.access_level === "read" ? (
              <Tooltip title="View" placement="bottomLeft">
                <span>
                  <Read />{" "}
                </span>
              </Tooltip>
            ) : item?.access_level === "write" ? (
              <Tooltip title="Add" placement="bottomLeft">
                <span>
                  <Add />
                </span>
              </Tooltip>
            ) : null,
          name: (
            <Tooltip
              key={item.id}
              placement="topLeft"
              title={item?.shareable_name}
            >
              <span
                className="container-ellipsis"
                key={`upload${item?.id || index + 1}`}
                onClick={() => {
                  if (item?.shareable_type !== "media") {
                    navigate(path);
                  }
                }}
                style={{
                  cursor:
                    item?.shareable_type !== "media" ? "pointer" : "default",
                }}
              >
                <span>
                  <img
                    src={
                      item?.is_encrypted
                        ? Shildicon
                        : getImage(
                            item?.format,
                            item?.ipfs_url,
                            item?.shareable_id,
                            item?.shareable_type,
                          )
                    }
                    alt=""
                  />
                  {item?.shareable_name.length < 20 ? (
                    <span> {item?.shareable_name}</span>
                  ) : (
                    <Tooltip
                      key={item.id}
                      placement="topLeft"
                      title={item?.shareable_name}
                    >
                      <span> {formatString(item?.shareable_name, 6, 6)}</span>
                    </Tooltip>
                  )}
                </span>
              </span>
            </Tooltip>
          ),
          wallet_address: (
            <Tooltip
              key={item.id}
              placement="topLeft"
              title={item?.wallet_address}
            >
              {formatString(item?.wallet_address, 6, 6)}
            </Tooltip>
          ),
          email: <p className="ellipsis ">{item?.email || ""}</p>,
          sharedDate: convertDate(item?.access_granted_date) || "",
          blankIcon: (
            <Dropdown menu={{ items: getItems(item) }} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()} className="threeDot">
                <ThreeDots />
              </a>
            </Dropdown>
          ),
        };
      })
    : [];
  const themeChange = useSelector((state) => state.theme.themeColor);

  return (
    <div>
      {ismodalOpen && (
        <MediaDisplay
          selectedMedia={selectedFile}
          setModalOpen={setIsModalOpen}
        />
      )}
      {!loading ? (
        <Table
          id="sharedWithMe"
          columns={columns}
          dataSource={data}
          locale={{
            emptyText: (
              <Empty
                image={
                  <img
                    src={themeChange === "dark" ? darkimg : NoData}
                    alt="empty"
                  />
                }
                description="No Data have been shared with you yet"
              />
            ),
          }}
          scroll={{ y: 525, x: "auto" }}
          showSorterTooltip={{
            target: "sorter-icon",
          }}
          pagination={false}
          className="commontable"
        />
      ) : (
        <div className="tableSkeleton" data-testid="skeleton-container">
          <Skeleton active paragraph={{ rows: 5 }} />
        </div>
      )}
      <CommonModal
        className="containerModal"
        visible={detailView}
        title={`${itemDetails?.shareable_type?.charAt(0).toUpperCase()}${itemDetails?.shareable_type?.slice(1)} Details`}
        BtnText="Continue"
        icon={<AddContainerICon />}
        content={
          <div>
            <div className="containerModal_bottom">
              <div>
                <p>Name</p> <h4>{itemDetails?.shareable_name}</h4>
              </div>
              <div>
                <p>Email</p> <h4>{itemDetails?.email}</h4>
              </div>
              <div>
                <p>Type</p> <h4>{itemDetails?.shareable_type}</h4>
              </div>

              <div>
                <p>Files </p> <h4>{itemDetails?.media_count} </h4>
              </div>

              <div>
                <p>Size</p> <h4>{formatStorageSize(itemDetails?.storage)}</h4>
              </div>
              <div>
                <p>Access Granted Date</p>{" "}
                <h4>{convertDate(itemDetails?.access_granted_date)}</h4>
              </div>
            </div>
          </div>
        }
        onOk={closeModal}
        onCancel={closeModal}
      />
      <Modal
        className="modal_lock"
        open={isModalOpeneEncyption}
        onOk={closeModal}
        onCancel={closeModal}
      >
        <div className="modal_main">
          <div className="imgdive">
            <img src={Shildicon} alt="Shield Icon" />
          </div>
          <h4>This file is encrypted</h4>
          <p>To access the file, you must first download it.</p>
          <button
            className="customButton customButton-smallBtn modalbtn"
            id="create-file-button"
            onClick={() => handleDownload(downloadIdEncryption, downloadFormat)}
          >
            <p>Download</p>
            <span className="customButton-icon download">
              <DownloadFile />
            </span>
          </button>
        </div>
      </Modal>
    </div>
  );
}
SharedWithMeTable.propTypes = {
  searchTerm: PropTypes.string.isRequired,
};
export default SharedWithMeTable;
