import React, { useEffect, useState } from "react";
import "./BillingInformation.scss";
import {
  DownloadFile,
  DropdownIcon,
  PasswordKeyIcon,
} from "../../Assets/Assets";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import BillingTable from "./BillingTable";
import CommonModal from "../../Common/CommonModal/CommonModal";
import CustomSelect from "../../Common/Components/CustomSelect/CustomSelect";
import toast from "react-hot-toast";
import { Dropdown, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import httpRequest from "../../Utils/httpsRequest";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../constants";
import useLogout from "../../CustomHooks/useLogout";
import { useSelector } from "react-redux";
import { convertDate } from "../../Utils/helpers";
// import PdfGenerator from "./pdfGenration";

function BillingInformation() {
  const handleLogOut = useLogout();
  const [data, setData] = useState([]);
  const [visibleModal, setVisibleModal] = useState(null);
  const { csrfToken } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const items = [
    {
      label: <span>Success</span>,
      value: "success",
      key: "0",
    },
    {
      label: <span>Pending</span>,
      value: "pending",
      key: "1",
    },
    {
      label: <span>Failed</span>,
      value: "failed",
      key: "2",
    },
  ];
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelect = (e) => {
    const selectedItem = items.find((item) => item.key === e.key);
    if (selectedItem) {
      const { value } = selectedItem;

      setSelectedValue(value);
    }
    // setSelectedValue(e.key.value);
  };

  useEffect(() => {
    const fetchBillingData = async () => {
      try {
        setLoading(true);

        const res = await httpRequest(
          // API_URL.BILLING_LIST`&status=${selectedValue}`,
          // `${API_URL.BILLING_LIST}/status=${selectedValue}`,
          `${API_URL.BILLING_LIST}?status=${selectedValue}`,
          null,
          HTTP_METHOD.GET,
          {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          null,
          handleLogOut,
        );

        if (res.code === HTTP_STATUS_CODE.OK) {
          setData(res.data);
        } else {
          console.error("Failed to fetch billing data:", res.error);
        }
      } catch (error) {
        console.error("Failed to fetch billing data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBillingData();
  }, [selectedValue]);

  const handleOk = () => {
    setVisibleModal(null);
  };

  const handleCancel = () => {
    setVisibleModal(null);
  };

  const handleDownloadCsv = () => {
    try {
      if (data?.length) {
        setBtnLoading(true);
        const csvData = [
          [
            "Sr. No",
            "Payment Date",
            "Due Date",
            "Plan",
            "Price",
            "Status",
            "Transaction Hash",
          ],
          ...data.map((item, index) => [
            index + 1,
            item?.payment_date
              ? convertDate(item?.payment_date)
              : new Date().toLocaleDateString(),
            item?.end_date
              ? convertDate(item?.end_date)
              : new Date().toLocaleDateString(),
            item.plan_name,
            item.amount,
            item.status,
            item.transaction_id,
          ]),
        ];

        const csvContent =
          "data:text/csv;charset=utf-8," +
          csvData.map((row) => row.join(",")).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "billing.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setBtnLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong while downloading!");
      setBtnLoading(false);
    }
  };

  return (
    <div className="billing ">
      <div className="billing_bottom">
        <h3>Billing History</h3>
        <div className="billing_action">
          <Dropdown
            className="menuDropdown billing_dropdown"
            overlayClassName="userDropdown"
            menu={{ items, onClick: handleSelect }}
            trigger={["click"]}
          >
            <div>
              Filter
              <DropdownIcon />
            </div>
          </Dropdown>
          <div
            className="fileManagement_heading_btn"
            onClick={btnLoading ? null : handleDownloadCsv}
          >
            <button
              className={`customButton customButton-smallBtn flex_download ${btnLoading ? "disableBtn" : ""} `}
              id="create-file-button"
              disabled={btnLoading}
            >
              {btnLoading ? (
                <Spin indicator={<LoadingOutlined spin />} />
              ) : (
                <>
                  <span className="customButton-icon download">
                    <DownloadFile />
                  </span>
                  <p>Download </p>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      {/* <button
                onClick={generatePdf}
                icon={<DownloadIcon />}
                type="button"
                shape="rectangle"
                size="small"
              >
                download
        </button> */}
      <div>
        <BillingTable loading={loading} data={data} />
      </div>

      <CommonModal
        visible={visibleModal === "billingAddress"}
        title="Update Billing Address"
        BtnText="Save"
        customBtutton
        paragraph="Lorem ipsum dolor sit amet consectetur. Mollis fringilla viverra sagittis sed aliquet malesuada faucibus felis cum. At aliquet condimentum et accumsan mauris porttitor egestas."
        icon={<PasswordKeyIcon />}
        content={
          <>
            <InputCustom
              regularInput
              placeholder="Name"
              value="Jaqueline C. Banks"
              label
              labletext="Full Name"
              required
            />
            <CustomSelect
              label
              labelText="Country or Region"
              defaultValue="United State"
              options={[
                { value: "United State", label: "United State" },
                { value: "India", label: "India" },
                { value: "Canada", label: "Canada" },
              ]}
            />
            <InputCustom
              regularInput
              placeholder="Address"
              value="4454 Saints Alley Plant City, FL 33564"
              label
              labletext="Address"
              required
            />
          </>
        }
        onOk={handleOk}
        onCancel={handleCancel}
      />

      <CommonModal
        visible={visibleModal === "taxInformation"}
        title="Add Tax ID"
        BtnText="Save"
        customBtutton
        paragraph="Lorem ipsum dolor sit amet consectetur. Mollis fringilla viverra sagittis sed aliquet malesuada faucibus felis cum. At aliquet condimentum et accumsan mauris porttitor egestas."
        icon={<PasswordKeyIcon />}
        content={
          <>
            <CustomSelect
              label
              labelText="Country or Region"
              defaultValue="United State"
              options={[
                { value: "United State", label: "United State" },
                { value: "India", label: "India" },
                { value: "Canada", label: "Canada" },
              ]}
            />
            <CustomSelect
              label
              labelText="Tax ID Type"
              defaultValue="Select Tax ID"
              options={[
                { value: "Select Tax ID", label: "Select Tax ID" },
                { value: "India", label: "India" },
                { value: "Canada", label: "Canada" },
              ]}
            />
            <InputCustom
              regularInput
              placeholder="Tax ID"
              label
              labletext="Enter Here"
              required
            />
          </>
        }
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </div>
  );
}

export default BillingInformation;
