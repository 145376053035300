import React from "react";
import PropTypes from "prop-types";
// import PdfGenerator from "./pdfGenration";
import { Empty, Skeleton, Table } from "antd";
import { convertDate, formatString } from "../../Utils/helpers";
import NoData from "../../Assets/images/notfound.svg";
import darkimg from "../../Assets/images/no-data-found-pink.png";
import { CopyIcon } from "../../Assets/Assets";
import toast from "react-hot-toast";
import { env } from "../../constants/env";
import { useSelector } from "react-redux";
function BillingTable({ data, loading }) {
  const columns = [
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (e) => {
        return convertDate(e) || "-";
      },
    },
    {
      title: "Due Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (e) => {
        return convertDate(e) || "-";
      },
    },
    {
      title: "Plan",
      dataIndex: "plan_name",
      key: "plan_name",
      render: (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount, record) => {
        return (
          <span>
            {amount} {record.token === "tomi" ? "Tomi" : "USDT"}
          </span>
        );
      },
    },

    {
      key: "Transaction ID",
      title: "Transaction ID",
      dataIndex: "transaction_id",
      render: (transaction_id) =>
        transaction_id ? (
          <>
            <a
              href={`${env.explorerUrl}/tx/${transaction_id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: "8px" }}
            >
              {formatString(transaction_id, 10, 10)}
            </a>
            <span
              className="cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(transaction_id);
                toast.success("Transaction ID copied!");
              }}
            >
              <CopyIcon style={{ color: "#1890ff" }} />
            </span>
          </>
        ) : (
          <>-</>
        ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e) => {
        return e === "success" ? (
          e.charAt(0).toUpperCase() + e.slice(1)
        ) : (
          <span className="rejected">Failed</span>
        );
      },
    },
  ];
  const themeChange = useSelector((state) => state.theme.themeColor);

  return (
    <div>
      {loading ? (
        <div className="no-events-found" data-testid="skeleton-container">
          <Skeleton active paragraph={{ rows: 5 }} />
        </div>
      ) : (
        <Table
          columns={columns}
          // scroll={{ y: 550, x: "max-content" }}
          dataSource={data}
          className="commontable"
          locale={{
            emptyText: (
              <Empty
                image={
                  <img
                    src={themeChange === "dark" ? darkimg : NoData}
                    alt="empty"
                  />
                }
                description="No Data Found"
              />
            ),
          }}
          pagination={false}
        />
      )}
    </div>
  );
}

BillingTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default BillingTable;
