import React, { useState } from "react";
import "./HeaderCommon.scss";
import HeaderLogo from "../../../Assets/images/HeaderLogo.png";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import PropTypes from "prop-types";
import { SignOutIcon } from "../../../Assets/Assets";
import httpRequest from "../../../Utils/httpsRequest";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../../constants";
import { clearUploadFileQueue } from "../../../store/slices/uiSettingSlice";
import { useSelector } from "react-redux";
import useLogout from "../../../CustomHooks/useLogout";
import { ContactUs } from "../../../Assets/Assets";

import { Tooltip } from "antd";
import ContactUsModal from "../../../Pages/ContactUsModal/ContactUsModal";
function HeaderCommon({ SignUpBtn, DisconnectBtn }) {
  const handleLogout = useLogout();
  const { csrfToken } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);

  const handleSignOut = async () => {
    try {
      const response = await httpRequest(
        API_URL.LOGOUT,
        HTTP_METHOD.DELETE,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogout,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        handleLogout();
        clearUploadFileQueue();
      } else {
        throw new Error(response?.response?.data?.message ?? "");
      }
    } catch (error) {
      console.log("Error while signing out: ", error);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div className="headerCommon">
      <img src={HeaderLogo} alt="Logo" />
      {SignUpBtn && (
        <div className="headerCommon_signBtn">
          <ButtonCustom regularBtn label="Sign Up" />
        </div>
      )}
      {DisconnectBtn && (
        <span className="contact_disconnect">
          <Tooltip placement="topLeft" title="Contact Us">
            <span onClick={openModal}>
              <ContactUs />
            </span>
          </Tooltip>

          <ButtonCustom
            regularBtn
            className="disconnect_btn"
            label={"Disconnect"}
            onClick={handleSignOut}
            lefticon={<SignOutIcon />}
          />

          <ContactUsModal closeModal={closeModal} show={showModal} />
        </span>
      )}
    </div>
  );
}

export default HeaderCommon;
HeaderCommon.propTypes = {
  SignUpBtn: PropTypes.bool,
  DisconnectBtn: PropTypes.bool,
};
