import "./AccountType.scss";
import { Spin } from "antd";
import { Switch } from "antd";
import { ethers } from "ethers";
import toast from "react-hot-toast";
import { env } from "../../constants/env";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import httpRequest from "../../Utils/httpsRequest";
import { LoadingOutlined } from "@ant-design/icons";
import useLogout from "../../CustomHooks/useLogout";
import { useDispatch, useSelector } from "react-redux";
import faitIcon from "../../Assets/images/dollarIocn.png";
import TomiToken from "../../Assets/images/TomiToken.svg";
import { fetchPlans } from "../../store/slices/planSlice";
import ERC20Contract from "../../helper/erc20.connection";
import { Path } from "../../Routing/Constant/RoutePaths";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { ProAccountIcon, ScaleIcon } from "../../Assets/Assets";
import { setMainLoading } from "../../store/slices/uiSettingSlice";
import AccountCard from "../../Common/Cards/AccountCard/AccountCard";
import {
  formatNumber,
  formatStorageSize,
  fetchOwnerBalance,
} from "../../Utils/helpers";
import { ERR_MSG } from "../../constants";
import SubscriptionContract from "../../helper/subscription.connection";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../constants";
import HeaderCommon from "../../Common/Components/HeaderCommon/HeaderCommon";
import {
  capitalizeFirstLetter,
  roundToTwoDecimalPlaces,
} from "../../Utils/helpers";
import {
  setPlanId,
  setPlanType,
  setNextPlan,
  setPaymentProcessModal,
} from "../../store/slices/userSlice";

function AccountType() {
  const perMonth = " per month";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogOut = useLogout();
  const [paused, setPaused] = useState();
  const [decimals, setDecimals] = useState(0);
  const [isLoading, setLoading] = useState("");
  const [capValueUsdt, setCapValueUsdt] = useState(0);
  const [capValueTomi, setCapValueTomi] = useState(0);
  const { walletProvider } = useWeb3ModalProvider();
  const [userBalance, setUserBalance] = useState(0);
  const { plans, loading } = useSelector((state) => state.plan);
  const [tomiTokenAddress, setTomiTokenAddress] = useState(null);
  const [usdtTokenAddress, setUsdtTokenAddress] = useState(null);
  const [tomiTokenSelected, setTomiTokenSelected] = useState(true);
  const { planType } = useSelector((state) => state.user);
  const { csrfToken, walletAddress } = useSelector((state) => state.auth);

  /**
   * get  contract  paused unPaused Status
   */
  const fetchDatas = async () => {
    try {
      if (walletProvider) {
        const subscription = await SubscriptionContract.init(walletProvider);
        const isPaused = await subscription.paused();
        setPaused(isPaused);
      }
    } catch (error) {
      console.error("Error fetching contract status:", error);
    }
  };
  /**
   * get token address from Api
   */
  useEffect(() => {
    if (planType) {
      navigate(Path.PLAN);
    }
    (async () => {
      const response = await httpRequest(
        API_URL.TOKEN_ADDRESSES,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );
      if (response.code === HTTP_STATUS_CODE.OK) {
        const tomiIndex = response?.data.findIndex(
          (item) => item.name === "tomi",
        );
        const usdtIndex = response?.data.findIndex(
          (item) => item.name === "usdt",
        );
        setTomiTokenAddress(
          response?.data[tomiIndex].address ?? env.tokenContractAddress,
        );
        setUsdtTokenAddress(
          response?.data[usdtIndex].address ?? env.tokenContractAddressUsdt,
        );
      }
    })();
  }, []);

  useEffect(() => {
    try {
      if (walletProvider) {
        dispatch(fetchPlans({ csrfToken, handleLogOut }));

        (async () => {
          const response = await httpRequest(
            `${API_URL.COIN_MARKET_CAP}`,
            HTTP_METHOD.GET,
            null,
            {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            null,
            handleLogOut,
          );

          if (response.code === HTTP_STATUS_CODE.OK) {
            setCapValueUsdt(Number(response.data?.USDT ?? 0));
            setCapValueTomi(Number(response.data?.TOMI ?? 0));
          }
        })();
      }
    } catch (error) {
      console.log("Error while fetching cap api : ", error);
    }
  }, [walletProvider]);

  /**
   * call fetch balance function when tomi token address and usdt token address is available
   */
  useEffect(() => {
    if (walletProvider && tomiTokenAddress && usdtTokenAddress) {
      (async () => {
        await fetchBalance(
          tomiTokenSelected ? tomiTokenAddress : usdtTokenAddress,
        );
      })();
    }
  }, [walletProvider, tomiTokenSelected, tomiTokenAddress, usdtTokenAddress]);

  const generateFeatures = (plan) => {
    return [
      `${formatStorageSize(plan?.storage)} Storage included`,
      `${formatStorageSize(plan?.meta_data?.bandwidth)} Bandwidth`,
      `${plan?.meta_data?.workspace} ${plan?.meta_data?.workspace == 1 ? "Workspace Member" : "Workspace Members"}`,
      `${plan?.meta_data?.gateway}  ${plan?.meta_data?.gateway === 1 ? "Gateway" : "Gateways"}`,
      `${formatNumber(plan?.meta_data?.requests)}  requests${perMonth}`,
      `Maximum number of files pinned can be ${formatNumber(plan?.meta_data?.pinned)}`,
      `${plan?.meta_data?.html_file === true ? "HTML Upload Supported" : "No HTML Upload Supported"}`,
      `${plan?.meta_data?.html_file === true ? "API key Access" : "No API key Access"}`,
    ];
  };

  const fetchBalance = async (contractAddress, setBalance = true) => {
    try {
      const erc20 = new ERC20Contract();
      await erc20.init(walletProvider, contractAddress);
      const decimals = await erc20.getDecimals(walletAddress);
      const balance = await erc20.getBalance(walletAddress);
      if (setBalance) {
        setDecimals(Number(decimals));
      }

      if (balance > 0 && setBalance) {
        setUserBalance(Number(balance) / 10 ** (Number(decimals) ?? 18));
      }
      dispatch(setMainLoading(false));
      return Number(balance) / 10 ** (Number(decimals) ?? 18);
    } catch (error) {
      console.error("Error fetching balance:", error);
      dispatch(setMainLoading(false));
      return 0;
    }
  };

  const handleSubmit = async (e) => {
    fetchDatas();

    if (paused) {
      toast.error("Contract currently paused by owner");
      return;
    }
    //  console.log(e?.holdings, e?.name)
    if (e?.holdings === 0 || e.name === "free") {
      const balance = await fetchBalance(tomiTokenAddress, false);
      if (balance >= e?.holdings) {
        setLoading(e?.name);
        const response = await httpRequest(
          API_URL.TRIAL_PLAN,
          HTTP_METHOD.POST,
          null,
          {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          null,
          handleLogOut,
        );

        if (response?.code === HTTP_STATUS_CODE.OK) {
          navigate(Path.DASHBOARD);
          dispatch(setPlanType(e?.name));
        } else {
          toast.error("Something went wrong!");
        }
      } else {
        toast.error(
          `A minimum of ${e?.holdings ?? 1000} Tomi tokens is required to access the free plan`,
        );
      }
      setLoading("");
    } else {
      try {
        const response = await httpRequest(
          API_URL.ADMIN_ADDRESS,
          HTTP_METHOD.GET,
          null,
          {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          null,
          handleLogOut,
        );

        if (!response?.data?.ApproverAddress) {
          toast.error("Failed to fetch Admin address");
          return;
        }

        const ownerBalance = await fetchOwnerBalance(
          walletProvider,
          response?.data?.ApproverAddress,
        );

        if (ownerBalance <= 0.001) {
          toast.error(ERR_MSG.INSUFFICIENT_OWNER_BALANCE);
        } else {
          const price = tomiTokenSelected ? e?.price : e?.price_usdt;
          const { tokenVal, tokenWithDecimals } = fetchTokenPrice(price);

          const tokenAddress = tomiTokenSelected
            ? tomiTokenAddress
            : usdtTokenAddress;

          try {
            if (userBalance >= tokenVal) {
              setLoading(e?.name);

              const erc20 = new ERC20Contract();
              await erc20.init(walletProvider, tokenAddress);

              const allowanceVal = await erc20.getAllowance(walletAddress);

              let allowanceHash = "";
              if (Number(allowanceVal) / 10 ** Number(decimals) < tokenVal) {
                const response = await erc20.approve(
                  env.subscriptionContractAddress,
                  tokenVal,
                );

                allowanceHash = response?.hash;
              } else {
                allowanceHash = "hash";
              }

              if (allowanceHash) {
                setTimeout(() => {
                  (async () => {
                    try {
                      setLoading(e?.name);
                      const subscription =
                        await SubscriptionContract.init(walletProvider);

                      const response = await httpRequest(
                        API_URL.PAID_PLAN,
                        HTTP_METHOD.POST,
                        {
                          price: tokenVal,
                          plan_name: e?.name,
                          price_usd: price,
                          token_address: tokenAddress,
                        },
                        {
                          "Content-Type": "application/json",
                          "X-CSRF-Token": csrfToken,
                        },
                        null,
                        handleLogOut,
                      );

                      const result = await subscription.subscribePlan(
                        e?.plan_key,
                        tokenAddress,
                        tokenWithDecimals,
                      );

                      if (result?.hash) {
                        navigate(Path.DASHBOARD);
                        dispatch(setNextPlan(e?.name));
                        dispatch(setPlanId(response?.data?.ID));
                        dispatch(setPaymentProcessModal(true));
                      } else {
                        dispatch(setNextPlan(null));
                        dispatch(setPlanId(null));
                        dispatch(setPaymentProcessModal(false));
                      }
                      setLoading("");
                    } catch (error) {
                      console.log("Error while subscribing : ", error);

                      setLoading("");
                      dispatch(setNextPlan(null));
                      dispatch(setPlanId(null));
                      dispatch(setPaymentProcessModal(false));

                      if (error instanceof Error) {
                        const err = JSON.parse(JSON.stringify(error));

                        if (err?.code === "ACTION_REJECTED") {
                          toast.error(
                            "Oops! It looks like you declined the request from your wallet",
                          );
                          return;
                        }

                        toast.error(
                          err?.reason ??
                            err?.error?.message ??
                            "Something went wrong",
                        );

                        return;
                      }

                      toast.error(error?.message ?? "Something went wrong");
                    }
                  })();
                }, 2000);
              } else {
                toast.error("Approval request does not succeed!");
                setLoading("");
              }
            } else {
              toast.error(
                `A minimum of ${Math.round(tokenVal * 100) / 100} Tomi tokens is required to access the ${e?.name} plan`,
              );
            }
          } catch (error) {
            console.log("error while upgrading plan to pro : ", error);
            if (error?.code === "ACTION_REJECTED") {
              toast.error(
                "Oops! It looks like you declined the request from your wallet",
              );
            }
            setLoading("");
          }
        }
      } catch (error) {
        console.error(error);
        toast.error("Oops! An unknown error occurred");
        return;
      }
    }
  };

  const handleToggle = (checked) => {
    setTomiTokenSelected(checked);
  };

  // const fetchTokenPrice = (priceUsd) => {
  //   try {
  //     if (tomiTokenSelected) {
  //       return Number(priceUsd) / Number(capValueTomi);
  //     } else {
  //       return Number(priceUsd) / Number(capValueUsdt);
  //     }
  //   } catch (error) {
  //     return 0;
  //   }
  // };

  const fetchTokenPrice = (priceUsd) => {
    try {
      if (tomiTokenSelected) {
        const amount = Number(priceUsd) / Number(capValueTomi) ?? 0;
        const tokenVal = parseFloat(amount.toFixed(2));

        const tokenWithDecimals = ethers.parseUnits(
          tokenVal.toString(),
          Number(decimals),
        );

        return {
          tokenVal,
          tokenWithDecimals: tokenWithDecimals,
        };
      } else {
        const amount = Number(priceUsd) / Number(capValueUsdt) ?? 0;
        const tokenVal = parseFloat(amount.toFixed(2));

        const tokenWithDecimals = ethers.parseUnits(
          tokenVal.toString(),
          Number(decimals),
        );

        return {
          tokenVal,
          tokenWithDecimals: tokenWithDecimals,
        };
      }
    } catch (error) {
      return null;
    }
  };

  const buttonText = (plan) => {
    if (isLoading === plan?.name) {
      return <Spin indicator={<LoadingOutlined spin />} />;
    } else if (plan?.name === "free") {
      return `Start ${capitalizeFirstLetter(plan?.name)} Plan`;
    } else {
      return `Start ${capitalizeFirstLetter(plan?.name)} Plan`;
    }
  };

  return (
    <div className="accountType dashAcType">
      <HeaderCommon DisconnectBtn />
      <h2>Choose Your Plan</h2>
      <div className="accountType_switch">
        <div
          className={`accountType_switch_head ${!tomiTokenSelected && "pink"}`}
        >
          <div>
            <img src={faitIcon} alt="" />
            <h5>Pay with USDT</h5>
          </div>
        </div>
        <Switch defaultChecked onChange={handleToggle} />
        <div
          className={`accountType_switch_head ${tomiTokenSelected && "pink"}`}
        >
          <div>
            <img src={TomiToken} alt="" />
            <h5>Pay with TOMI token</h5>
          </div>
        </div>
      </div>
      <div className="accountType_cards ">
        {plans &&
          plans?.map((plan, index) => (
            <AccountCard
              data={plan}
              key={index}
              title={plan?.name}
              price={`$${tomiTokenSelected ? (plan?.price ?? 0) : (plan.price_usdt ?? 0)}`}
              duration="mo"
              tomiImg={tomiTokenSelected}
              valueIconSrc={TomiToken}
              description={plan?.description || ""}
              features={generateFeatures(plan)}
              buttonText={buttonText(plan)}
              onClick={handleSubmit}
              value={roundToTwoDecimalPlaces(
                fetchTokenPrice(
                  tomiTokenSelected
                    ? (plan?.price ?? 0)
                    : (plan.price_usdt ?? 0),
                )?.tokenVal ?? 0,
              )}
              icon={
                plan?.name == "pro" ? (
                  <ProAccountIcon />
                ) : plan?.name == "scale" ? (
                  <ScaleIcon />
                ) : null
              }
              btnDisabled={isLoading && true}
              loading={loading}
            />
          ))}
      </div>
    </div>
  );
}

export default AccountType;
