import toast from "react-hot-toast";
import { env } from "../../constants/env";
import httpRequest from "../../Utils/httpsRequest";
import { Link, useNavigate } from "react-router-dom";
import { Turnstile } from "@marsidev/react-turnstile";
import { Path } from "../../Routing/Constant/RoutePaths";
import { ArrowIcon, NextArrow } from "../../Assets/Assets";
import { React, useEffect, useState, useRef } from "react";
import useKeyPress, { validateMail } from "../../Utils/validationUtils";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../constants/index";
import { useSelector } from "react-redux";

function ForgotPassword() {
  const ref = useRef();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mailErr, setMailErr] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  const [captchaToken, setCaptchaToken] = useState("");
  const { csrfToken } = useSelector((state) => state.auth);

  useEffect(() => {
    if (mailErr || !email || !captchaToken) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [mailErr, email, captchaToken]);

  const handleEmailChange = (e) => {
    try {
      const { isValidMail, error } = validateMail(e?.target?.value);

      if (!isValidMail) {
        setMailErr(error);
      } else {
        setMailErr("");
      }
      setEmail(e.target.value);
    } catch (error) {
      console.log("Error in forgot password : ", error);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!isDisable) {
        setIsDisable(true);

        const response = await httpRequest(
          API_URL.FORGOT_PASSWORD,
          HTTP_METHOD.POST,
          {
            email: email,
            captcha_token: captchaToken ?? "",
          },
          {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          null,
          null,
        );

        if (response.code === HTTP_STATUS_CODE.OK) {
          toast.success(
            response?.message || "Successfully  Forgot your password",
          );
          setEmail("");
          navigate("/");
        } else {
          setMailErr(response?.error?.message || "Something went wrong");
          toast.error(response?.error?.message || "Something went wrong");
        }
        ref.current?.reset();
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  useKeyPress("Enter", handleSubmit, [handleSubmit]);
  const handleClearError = () => {
    setMailErr("");
  };

  return (
    <div>
      <InputCustom
        regularInput
        placeholder="Enter here"
        label
        onChange={handleEmailChange}
        labletext="Email"
        required
        value={email}
        error={mailErr}
        onClearError={handleClearError}
      />
      <Turnstile
        ref={ref}
        siteKey={env.captchaKey}
        onSuccess={(token) => {
          setCaptchaToken(token);
        }}
        onError={(error) => {
          console.log("Error in captcha : ", error);
          setCaptchaToken("");
        }}
      />
      <div className="personalAccount_btns">
        <Link to={Path.WELCOME}>
          <ButtonCustom
            className="personalAccount_btns_back"
            label="Back"
            borderBtn
            lefticon={<NextArrow />}
          />
        </Link>
        <ButtonCustom
          regularBtn
          label="Continue"
          disabled={isDisable}
          className={isDisable ? "disableBtn" : ""}
          // disabled={email?.length > 0 ? false : true}
          icon={<ArrowIcon />}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

export default ForgotPassword;
