import {
  API_URL,
  BTN_LABLE,
  HTTP_METHOD,
  HTTP_STATUS_CODE,
  METAMASK_ERR_CODES,
} from "../../constants/index.js";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import {
  setWltSignature,
  setConnectButton,
} from "../../store/slices/uiSettingSlice.js";
import {
  logIn,
  setName,
  setIsNew,
  setDashboardGuide,
  setFileManagementGuide,
  setMemberGuide,
  setCsrfToken,
} from "../../store/slices/authSlice";
import "./Login.scss";
import toast from "react-hot-toast";
import { BrowserProvider } from "ethers";
import React, { useEffect, useState } from "react";
import { env } from "../../constants/env.js";
import httpRequest from "../../Utils/httpsRequest";
import { useNavigate, Link } from "react-router-dom";
import { formatString } from "../../Utils/helpers.js";
import { useDispatch, useSelector } from "react-redux";
import useLogout from "../../CustomHooks/useLogout.jsx";
import { Path } from "../../Routing/Constant/RoutePaths";
import useKeyPress from "../../Utils/validationUtils.js";
import { setPaymentPlan, setUserData } from "../../store/slices/userSlice.js";
import { DisconnectWalletIcon, NextArrow } from "../../Assets/Assets.jsx";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import { setContainerSharedTabVisible } from "../../store/slices/userSlice.js";
import LaunchModal from "../LaunchModal/LaunchModal.jsx";
import localStorageUtil from "../../Utils/localStorage.js";

function Welcome() {
  const { open } = useWeb3Modal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = useLogout();
  const [show, setShow] = useState(false);
  const [isClosedManually, setIsClosedManually] = useState(false);
  const { walletProvider } = useWeb3ModalProvider();
  const { address, isConnected } = useWeb3ModalAccount();
  const { connectBtnLable, wltSignature } = useSelector(
    (state) => state.uiSettings,
  );

  useEffect(() => {
    if (!address) {
      dispatch(setConnectButton(BTN_LABLE.CONNECT));
    }
  }, []);

  useEffect(() => {
    if (!wltSignature && address && walletProvider) {
      RequestWltSignature();
    }
  }, [wltSignature, address, walletProvider]);

  useEffect(() => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
      return null;
    };
    const hasBannerShown = getCookie("bannerShown");
    if (!isClosedManually && !hasBannerShown) {
      setTimeout(() => {
        setShow(true);
        document.cookie =
          "bannerShown=false; path=/; max-age=" + 60 * 60 * 24 * 365;
      }, 2000);
    }
  }, [isClosedManually]);

  const closeModal = () => {
    setShow(false);
    setIsClosedManually(true);
  };

  const RequestWltSignature = async () => {
    try {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const signedMessage = await signer.signMessage(env.wltSignatureMsg);
      dispatch(setWltSignature(signedMessage));
    } catch (error) {
      if (error instanceof Error) {
        const err = JSON.parse(JSON.stringify(error));
        if (err.code === "ACTION_REJECTED") {
          toast.error(
            "Oops! It looks like you declined the request from your wallet",
          );
        }
      } else {
        toast.error(error?.message ?? "Something went wrong");
      }
    }
  };

  const handleClick = async () => {
    try {
      if (connectBtnLable === BTN_LABLE.DISCONNECT) {
        handleLogOut();
        dispatch(setConnectButton(BTN_LABLE.CONNECT));
        return;
      }
      if (!isConnected && !address) {
        await open();
      }
      if (!wltSignature && walletProvider) {
        RequestWltSignature();
      }
    } catch (error) {
      console.log("Error while connecting wallet: ", error);
      if (error?.code === METAMASK_ERR_CODES.REJECT) {
        toast.error(error?.message ?? "User rejected the request");
      }
    }
  };

  useKeyPress("Enter", handleClick, [handleClick]);

  const loginRequest = async () => {
    try {
      let signature = null;
      if (wltSignature) {
        signature = wltSignature;
      } else {
        try {
          const provider = new BrowserProvider(walletProvider);
          const signer = await provider.getSigner();
          const signedMessage = await signer.signMessage(env.wltSignatureMsg);
          signature = signedMessage;
          dispatch(setWltSignature(signature));
        } catch (error) {
          if (error instanceof Error) {
            const err = JSON.parse(JSON.stringify(error));
            if (err.code === "ACTION_REJECTED") {
              toast.error(
                "Oops! It looks like you declined the request from your wallet",
              );
            }
          } else {
            toast.error(error?.message ?? "Something went wrong");
          }
        }
      }

      if (signature) {
        const data = {
          wallet_address: address,
          message: env.wltSignatureMsg,
          signature,
          type: 1,
        };

        const response = await httpRequest(
          API_URL.LOGIN,
          HTTP_METHOD.POST,
          data,
          null,
          null,
          null,
        );

        if (response?.code === HTTP_STATUS_CODE.OK) {
          const csrfToken = localStorageUtil.getItem("csrfToken");
          dispatch(setCsrfToken(csrfToken));
          dispatch(setName(response?.data?.name));
          dispatch(setIsNew(response?.data?.is_first));
          dispatch(setDashboardGuide(response?.data?.is_dashboard_guide));
          dispatch(setFileManagementGuide(response?.data?.is_file_guide));
          dispatch(setMemberGuide(response?.data?.is_member_guide));
          dispatch(logIn());
          dispatch(setUserData(response?.data));
          dispatch(setConnectButton(BTN_LABLE.CONNECT));
          dispatch(
            setContainerSharedTabVisible(
              response?.data?.is_shared_container ?? false,
            ),
          );
          if (response?.data?.plan_type && response?.data?.plan_expiry) {
            dispatch(setPaymentPlan(null));
          }
        } else if (
          response?.code === HTTP_STATUS_CODE.BAD_REQUEST &&
          response?.error?.message === "User blocked by admin"
        ) {
          toast.error(response?.error?.message);
          dispatch(setConnectButton(BTN_LABLE.DISCONNECT));
        } else {
          navigate(Path.PERSONAL_ACCOUNT);
        }
      }
    } catch (error) {
      console.log("Error during login: ", error);
    }
  };

  useEffect(() => {
    if (wltSignature) {
      const handleLoginProcess = async () => {
        if (connectBtnLable !== BTN_LABLE.DISCONNECT) {
          if (address) {
            await loginRequest();
          } else {
            dispatch(setConnectButton(BTN_LABLE.CONNECT));
          }
        }
      };
      const timer = setTimeout(() => {
        handleLoginProcess();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [address, navigate, connectBtnLable, wltSignature]);

  return (
    <div className="welcomeSec">
      <ButtonCustom
        tooltip={
          connectBtnLable === BTN_LABLE.DISCONNECT &&
          BTN_LABLE.DISCONNECT + " Wallet"
        }
        className={`btn ${connectBtnLable === BTN_LABLE.DISCONNECT ? "disconnectBtn" : ""}`}
        label={connectBtnLable === BTN_LABLE.CONNECT && connectBtnLable}
        regularBtn
        onClick={handleClick}
        icon={
          connectBtnLable === BTN_LABLE.CONNECT ? (
            <NextArrow />
          ) : (
            <DisconnectWalletIcon />
          )
        }
      >
        {connectBtnLable === BTN_LABLE.DISCONNECT && (
          <div className="disconnectBtn_inner">
            {address && <span>{formatString(address, 9, 9)}</span>}
          </div>
        )}
      </ButtonCustom>

      <div className="forgetText">
        <Link to={Path.FORGOT_PASSWORD}>Forgot Account?</Link>
      </div>

      <LaunchModal show={show} closeModal={closeModal} />
    </div>
  );
}

export default Welcome;
