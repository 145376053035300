import React, { useState } from "react";
import "./InputCustom.scss";
import PropTypes from "prop-types";
import { SearchIcon } from "../../../Assets/Assets";
import VisibilityOff from "../../../Assets/images/VisibilityOff.svg";
import VisibilityOn from "../../../Assets/images/VisibilityOn.svg";
import { CloseCircleOutlined } from "@ant-design/icons";

function InputCustom(props) {
  const {
    label,
    labletext,
    onclickBtn,
    rightBtn,
    rightBtnText,
    placeholder,
    type,
    error,
    regularInput,
    passwordInput,
    textareaInput,
    id,
    name,
    onChange,
    rightIcon,
    value,
    customClass,
    searchInputs,
    tabIndex,
    onBlur,
    disabled,
    required = false,
    maxLength,
    minLength,
    rows,
    onClearError,
    onclickIcon,
  } = props;

  const [types, setTypes] = useState(false);

  const handleClear = () => {
    if (onChange) {
      onChange({ target: { value: "" } });
    }
  };

  return (
    <div
      className={`customInput ${
        passwordInput ? "customInput-password" : ""
      } ${customClass} ${error ? "customInput-inputError" : ""}`}
    >
      {label && (
        <label>
          {labletext}
          {required && <span> *</span>}
        </label>
      )}
      {regularInput && !textareaInput && (
        <input
          placeholder={placeholder}
          type={type}
          id={id}
          onKeyDown={(event) => {
            if (event.key === "Backspace" && onClearError) {
              onClearError();
            }
          }}
          autoComplete="off"
          onChange={onChange}
          name={name}
          value={value}
          tabIndex={tabIndex}
          onBlur={onBlur}
          disabled={disabled}
          maxLength={maxLength}
        />
      )}
      {passwordInput && (
        <div className="customInput-password_wrapper">
          <input
            placeholder={placeholder}
            type={types ? "text" : "password"}
            id={id}
            autoComplete="new-password"
            onFocus="this.removeAttribute('readonly');"
            name={name}
            onChange={onChange}
            value={value}
            tabIndex={tabIndex}
            onBlur={onBlur}
            maxLength={maxLength}
          />
          <button
            onClick={() => {
              setTypes(!types);
            }}
            type="button"
          >
            {types ? (
              <img
                src={VisibilityOn}
                alt="VisibilityOn"
                width={24}
                height={24}
              />
            ) : (
              <img
                src={VisibilityOff}
                alt="VisibilityOff"
                width={24}
                height={24}
              />
            )}
          </button>
        </div>
      )}
      {textareaInput && (
        <textarea
          placeholder={placeholder}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          tabIndex={tabIndex}
          onBlur={onBlur}
          disabled={disabled}
          maxLength={maxLength ? maxLength : 300}
          rows={rows || 4}
          style={{ fontFamily: "sans-serif" }}
        />
      )}

      {error && <p className="error">{error}</p>}
      {searchInputs && (
        <div className="search-input-wrapper">
          <input
            type="text"
            autoComplete="off"
            placeholder={placeholder}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            maxLength={maxLength}
            minLength={minLength}
          />
          <button type="button" className="search-button">
            <SearchIcon />
          </button>

          {/* Conditionally render the clear button when input value is not empty */}
          {value && value.length > 0 && (
            <button
              type="button"
              className="clear-button"
              onClick={handleClear}
            >
              <CloseCircleOutlined />
            </button>
          )}
        </div>
      )}
      {rightBtn && (
        <button onClick={onclickBtn} className="customInput-rightBtn">
          {rightBtnText}
        </button>
      )}
      {rightIcon && (
        <button onClick={onclickIcon} className="customInput-rightIcon">
          {rightIcon}
        </button>
      )}
    </div>
  );
}

InputCustom.propTypes = {
  label: PropTypes.bool,
  labletext: PropTypes.string,
  onclickBtn: PropTypes.func,
  rightBtn: PropTypes.bool,
  rightBtnText: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  regularInput: PropTypes.bool,
  passwordInput: PropTypes.bool,
  textareaInput: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  customClass: PropTypes.string,
  searchInputs: PropTypes.bool,
  tabIndex: PropTypes.number,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  rows: PropTypes.number,
  onClearError: PropTypes.func,
  rightIcon: PropTypes.element,
  onclickIcon: PropTypes.func,
};

export default InputCustom;
