import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  folderChain: [],
};

const breadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    addToBreadcrumb(state, action) {
      try {
        const newFolder = action.payload;
        if (!newFolder || typeof newFolder !== "object" || !newFolder.id) {
          console.error("Invalid folder structure: ", newFolder);
          return;
        }
        const isUnique = !state.folderChain.some((folder) => {
          return folder.id === newFolder.id;
        });

        if (isUnique) {
          state.folderChain.push(newFolder);
        }
      } catch (error) {
        console.error("Error adding to breadcrumb:", error);
      }
    },

    removeItemFromBreadcrumb(state, action) {
      try {
        const itemToRemove = action.payload;

        if (
          !itemToRemove ||
          typeof itemToRemove !== "object" ||
          !itemToRemove.href
        ) {
          console.error("Invalid item structure for removal:", itemToRemove);
          return;
        }

        const updatedChain = state.folderChain.filter(
          (item) => item.id !== itemToRemove.href,
        );

        if (updatedChain.length === state.folderChain.length) {
          console.warn("No matching folder found for removal:", itemToRemove);
        } else {
          state.folderChain = updatedChain;
        }
      } catch (error) {
        console.error("Error removing item from breadcrumb:", error);
      }
    },

    removeFromBreadcrumb(state, action) {
      try {
        const index = action.payload;

        if (
          typeof index !== "number" ||
          index < 0 ||
          index >= state.folderChain.length
        ) {
          console.error("Invalid index for breadcrumb removal:", index);
          return;
        }
        state.folderChain = state.folderChain.slice(0, index + 1);
      } catch (error) {
        console.error("Error removing from breadcrumb:", error);
      }
    },

    resetBreadcrumb(state) {
      try {
        state.folderChain = [];
      } catch (error) {
        console.error("Error resetting breadcrumb:", error);
      }
    },
  },
});

export const {
  addToBreadcrumb,
  removeFromBreadcrumb,
  resetBreadcrumb,
  removeItemFromBreadcrumb,
} = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
