/*eslint-disable*/
import toast from "react-hot-toast";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../Assets/Assets";
import httpRequest from "../../Utils/httpsRequest";
import React, { useEffect, useState } from "react";
import useKeyPress from "../../Utils/validationUtils";
import { Turnstile } from "@marsidev/react-turnstile";
import { useDispatch, useSelector } from "react-redux";
import { Path } from "../../Routing/Constant/RoutePaths";
import {
  logIn,
  setDashboardGuide,
  setFileManagementGuide,
  setMemberGuide,
  setName,
} from "../../store/slices/authSlice";
import {
  selectUserEmail,
  setContainerSharedTabVisible,
} from "../../store/slices/userSlice";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import {
  setConnectButton,
  setUserTempData,
} from "../../store/slices/uiSettingSlice";
import {
  API_URL,
  BTN_LABLE,
  HTTP_METHOD,
  HTTP_STATUS_CODE,
} from "../../constants";
import { env } from "../../constants/env";

const OtpVerification = () => {
  const ref = React.useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [otpErr, setOtpErr] = useState("");
  const userEmail = useSelector(selectUserEmail);
  const userData = useSelector((state) => state.user);
  const [captchaToken, setCaptchaToken] = useState("");
  const [isTimerVisible, setIsTimerVisible] = useState(true);
  const { walletAddress } = useSelector((state) => state.auth);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);
  const { csrfToken } = useSelector((state) => state.auth);

  // UseEffect to enable/disable the continue button based on OTP state
  useEffect(() => {
    if (otp?.length < 6 || otpErr || !captchaToken) {
      setIsContinueDisabled(true);
    } else {
      setIsContinueDisabled(false);
    }
  }, [otp, otpErr, captchaToken]);

  useEffect(() => {
    const savedStartTime = localStorage.getItem("otpTimerStart");
    if (savedStartTime) {
      const elapsedTime = Math.floor((Date.now() - savedStartTime) / 1000);
      const newTimer = Math.max(60 - elapsedTime, 0); // Remaining time

      if (newTimer > 0) {
        setTimer(newTimer);
        setIsResendDisabled(true);
        setIsTimerVisible(true);
      } else {
        setTimer(60);
        setIsResendDisabled(true);
        setIsTimerVisible(true);
      }
    } else {
      setTimer(60);
      setIsTimerVisible(true);
      setIsResendDisabled(true);
      localStorage.setItem("otpTimerStart", Date.now());
    }
  }, []);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
      setIsTimerVisible(false);
      clearInterval(interval);
      localStorage.removeItem("otpTimerStart");
    }
    return () => clearInterval(interval);
  }, [timer]);

  const resetState = () => {
    setOtp("");
    setOtpErr("");
  };

  const handleBack = () => {
    resetState();
    navigate(Path.PERSONAL_ACCOUNT);
    localStorage.removeItem("otpTimerStart");
  };

  const resetStateForOtp = () => {
    setOtp("");
  };

  const handleOtpSubmit = async () => {
    try {
      if (!isContinueDisabled) {
        setIsContinueDisabled(true);
        const data = {
          email: userData?.email,
          otp,
          wallet_Address: walletAddress,
          captcha_token: captchaToken ?? "",
        };
        const response = await httpRequest(
          API_URL.VALIDATE_OTP,
          HTTP_METHOD.POST,
          data,
          {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          null,
          null,
        );
        if (response?.code === HTTP_STATUS_CODE.OK) {
          dispatch(setName(userData.name));
          dispatch(setConnectButton(BTN_LABLE.CONNECT));
          dispatch(logIn());
          dispatch(
            setUserTempData({
              name: "",
              email: "",
              useCase: "",
            }),
          );
          navigate(Path.ACCOUNT_TYPE);
          dispatch(setDashboardGuide(false));
          dispatch(setFileManagementGuide(false));
          dispatch(setMemberGuide(false));
          dispatch(setContainerSharedTabVisible(false));
        } else {
          toast.error(response?.error?.message || "Something went wrong");
          setIsContinueDisabled(true);
        }

        resetStateForOtp();
        ref.current?.reset();
      }
    } catch (error) {
      console.log("Error while validating OTP: ", error);
      toast.error("Incorrect OTP. Please try again");
    }
  };

  useKeyPress("Enter", handleOtpSubmit, [handleOtpSubmit]);

  const handleResendOtp = async () => {
    try {
      setIsResendDisabled(true);
      resetState();
      const data = {
        email: userData?.email,
        wallet_Address: walletAddress,
      };
      const response = await httpRequest(
        API_URL.RESEND_OTP,
        HTTP_METHOD.PUT,
        data,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        null,
      );
      if (response?.code === HTTP_STATUS_CODE.OK) {
        toast.success(response?.message || "Sucessfully resend");
        setIsResendDisabled(true);
        setIsTimerVisible(true);
        setTimer(60);
        localStorage.setItem("otpTimerStart", Date.now());
      } else {
        if (response?.code === 422) {
          // setOtpErr(response?.messsage);
          toast.error(response?.error?.message || "Something went wrong");
          return;
        }
        // setOtpErr(response?.error?.message);
        toast.error(response?.error?.message || "Something went wrong");
        setIsResendDisabled(false);
      }
    } catch (error) {
      console.log("Error while resending OTP: ", error);
    }
  };

  return (
    <>
      <div className="resendOtp-heading">
        Check your inbox! An OTP has been sent to{" "}
        <span className="userEmail">{userEmail}</span>. Please enter the OTP
        below to verify your account.
      </div>

      {/* <InputCustom
        required
        type="text"
        name="otp"
        regularInput
        label={true}
        labletext="OTP"
        error={otpErr}
        value={otp}
        minLength={6}
        maxLength={6}
        onChange={handleChange}
        placeholder="Enter OTP here..."
      /> */}
      <span className="otp-field">
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType="number"
          renderInput={(props) => <input {...props} />}
        />
      </span>

      <div className="resendOtp">
        {isTimerVisible && (
          <div className="resendOtp_btn">
            {`Resend OTP ${timer > 0 ? `in ${timer}s` : ""}`}
          </div>
        )}
        {!isResendDisabled && (
          <>
            <div
              className="resendOtp_btn"
              onClick={handleResendOtp}
              disabled={isResendDisabled}
            >
              Resend OTP
            </div>
          </>
        )}
      </div>

      <Turnstile
        ref={ref}
        siteKey={env.captchaKey}
        onSuccess={(token) => {
          setCaptchaToken(token);
        }}
        onError={(error) => {
          console.log("Error in captcha : ", error);
          setCaptchaToken("");
        }}
      />

      <div className="personalAccount_btns">
        <ButtonCustom
          className="personalAccount_btns_back"
          label="Back"
          // borderBtn
          regularBtn
          onClick={handleBack}
          lefticon={<ArrowIcon />}
        />

        <ButtonCustom
          regularBtn
          label="Continue"
          icon={<ArrowIcon />}
          onClick={handleOtpSubmit}
          disabled={isContinueDisabled}
          className={isContinueDisabled ? "disableBtn" : ""}
        />
      </div>
    </>
  );
};

export default OtpVerification;
