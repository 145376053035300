import {
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import "./Graph.scss";
import React, { useEffect, useState } from "react";

import { CustomTooltip } from "../../CustomTooltip/CustomTooltip ";
import {
  dateToWeek,
  displayDates,
  bytesToGenericUnit,
  YAxisMaxValue,
  convertBytesToSpecific,
} from "../../../Utils/helpers";

export default function Graph(graphData) {
  const [data, setData] = useState(null);
  const [tempData, setTempData] = useState(null);
  const period = graphData.period;
  const dataType = ["Bytes", "KB", "MB", "GB", "TB"];

  const tickCount = 10;

  const [yAxisUnit, setYAxisUnit] = useState(dataType[1]);
  const [maxUsageSize, setMaxUsageSize] = useState(0);
  const [yAxisMax, setYAxisMax] = useState(null);

  const [ticks, setTicks] = useState(null);
  useEffect(() => {
    if (graphData?.graphData) {
      setMaxUsageSize(0);
      setTempData(graphData?.graphData);
      findMax(graphData?.graphData);
    }
  }, [graphData]);

  const [visibility, setVisibility] = useState({
    upload_size: true,
    download_size: true,
  });

  const handleLegendClick = (e) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [e]: !prevVisibility[e],
    }));
  };

  /* Find max number in upload/download dataset */
  const findMax = (data) => {
    let max = 0;
    for (let unit of data) {
      if (data?.length > 0) {
        if (max == 0) {
          max = Math.max(unit.upload_size, unit.download_size);
        } else {
          let unitMax = Math.max(unit.upload_size, unit.download_size);
          max = Math.max(unitMax, max);
        }
      }
    }
    setMaxUsageSize(max);
  };

  /* Preparing an array of object for graph keeping units by default to kb*/
  useEffect(() => {
    if (yAxisUnit && tempData) {
      let data = [];
      for (let unit of tempData) {
        let obj = {
          date: unit.date,
          upload_size: convertBytesToSpecific(unit.upload_size, yAxisUnit),
          download_size: convertBytesToSpecific(unit.download_size, yAxisUnit),
        };

        data.push(obj);
      }
      setData(data);
    }
  }, [yAxisUnit, tempData]);

  let inputLabels = [
    {
      key: "upload_size",
      displayName: "Upload Usage",
      color: "#8884d8",
      stroke: "#c50267",
      background: "pinkColor",
      fill: "url(#color)",
    },
    {
      key: "download_size",
      displayName: "Download Usage",
      color: "white",
      background: "blueColor",
      stroke: "#1a73e8",
      fill: "url(#colorDownloadUsage)",
    },
  ];

  /* Figuring out the y-axis unit wrt max value in dataset 
  and setting the step values(ticks) in y-axis */
  useEffect(() => {
    if (maxUsageSize) {
      const { maxData, type } = bytesToGenericUnit(maxUsageSize);

      let ceilValue = Math.ceil(maxData);
      let tempYAxisMax;
      if (ceilValue / tickCount < 1) {
        ceilValue = 10;
        setYAxisMax(ceilValue);
        tempYAxisMax = ceilValue;
      } else {
        setYAxisMax(YAxisMaxValue(ceilValue));
        tempYAxisMax = YAxisMaxValue(ceilValue);
      }
      setYAxisUnit(type);
      setTicks(calculateTicks(tempYAxisMax, tempYAxisMax / 10));
    } else if (maxUsageSize == 0) {
      setYAxisUnit(dataType[1]);
      setYAxisMax(100);
      setTicks(calculateTicks(100, 20));
    }
  }, [maxUsageSize]);

  /* Function for calculating the step values in y-axis*/
  const calculateTicks = (maxValue, step) => {
    const ticks = [];
    for (let i = 0; i <= maxValue; i += step) {
      ticks.push(i);
    }
    return ticks;
  };

  return (
    <>
      <div className="graph_size">
        {inputLabels.map((elem, index) => {
          return (
            <div key={index + 1} onClick={() => handleLegendClick(elem.key)}>
              <div className={elem.background}></div>
              <p className={visibility[elem.key] ? "" : "strike-through"}>
                {elem.displayName}
              </p>
            </div>
          );
        })}
      </div>
      <ResponsiveContainer width="100%" height={450}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#e1066b" stopOpacity={1} />
              <stop offset="100%" stopColor="black" stopOpacity={0.92} />
            </linearGradient>
            <linearGradient id="colorDownloadUsage" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#1a73e8" stopOpacity={1} />
              <stop offset="100%" stopColor="black" stopOpacity={0.92} />
            </linearGradient>
          </defs>
          {inputLabels.map((label) => {
            return (
              <Area
                key={label.key}
                dataKey={label.key}
                stroke={label.stroke}
                hide={visibility[label.key] == false}
                fill={label.fill}
                type="monotone"
              />
            );
          })}

          <XAxis
            dataKey="date"
            axisLine={true}
            color="white"
            tickLine={false}
            tickFormatter={(str) => {
              if (period == "year") {
                const month = parseInt(str.split("-")[1], 10);

                const monthNames = [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ];
                return `${monthNames[month - 1]}`; // Return the corresponding month name
              }
              if (period == "week") {
                return dateToWeek(str);
              }
              if (period == "month" || period == "day") {
                return displayDates(str, period);
              }
            }}
            style={{ fill: "white" }}
          />

          {/* Y-Axis */}
          <YAxis
            axisLine={true}
            tickLine={false}
            domain={[0, yAxisMax]}
            tickFormatter={(number) => {
              return `${number}${yAxisUnit}`;
            }}
            style={{ fill: "white" }}
            padding={{ top: 20, bottom: 20 }}
            ticks={ticks}
          />

          <Tooltip
            content={
              <CustomTooltip
                isGraph={true}
                period={period}
                yAxisUnit={yAxisUnit}
              />
            }
          />
          <CartesianGrid opacity={0.1} vertical={false} />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
}
