import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  themeColor: "light",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      console.log("action.payload", action.payload);
      state.themeColor = action.payload;
    },
  },
});

export const gettheme = (state) => state.theme.themeColor;

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
