import { env } from "../constants/env";
import { BrowserProvider, ethers } from "ethers";
import SubscriptionAbi from "../constants/abi/subscription.json";

class SubscriptionContract {
  static instance = null;
  contract = null;

  constructor() {
    if (SubscriptionContract.instance) {
      return SubscriptionContract.instance;
    }
    SubscriptionContract.instance = this;
  }

  static async init(walletProvider) {
    if (!SubscriptionContract.instance) {
      const instance = new SubscriptionContract();
      await instance._initialize(walletProvider);
    }
    return SubscriptionContract.instance;
  }

  async _initialize(walletProvider) {
    try {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();

      this.contract = new ethers.Contract(
        env.subscriptionContractAddress,
        SubscriptionAbi,
        signer,
      );
    } catch (error) {
      console.error("Failed to initialize contract:", error);
      throw error;
    }
  }

  async subscribePlan(planType, tokenContractAddress, tokens) {
    try {
      if (!this.contract)
        throw new Error("Contract instance is not initialized.");

      return await this.contract.subscribe(
        planType,
        tokens,
        tokenContractAddress,
      );
    } catch (error) {
      console.log("Error while subsribing plans ", error);
      throw error;
    }
  }

  async paused() {
    try {
      if (!this.contract)
        throw new Error("Contract instance is not initialized.");
      const res = await this.contract.paused();

      return res;
    } catch (error) {
      console.error("Error while Add Admin :", error);
      throw error;
    }
  }
}

export default SubscriptionContract;
