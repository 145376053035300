import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpRequest from "../../Utils/httpsRequest";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../constants";

// Async Thunk for API Call
export const fetchUserGuide = createAsyncThunk(
  "userGuide/fetch",
  async ({ csrfToken, handleLogOut, payload }, { rejectWithValue }) => {
    const guideType =
      payload === "is_dashboard_guide"
        ? "is_dashboard_guide"
        : payload === "is_file_guide"
          ? "is_file_guide"
          : "is_member_guide";

    try {
      const response = await httpRequest(
        API_URL.USER_GUIDE_SHOWN,
        HTTP_METHOD.PUT,
        { name: guideType },
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response.code === HTTP_STATUS_CODE.OK) {
        return response.data;
      } else {
        return rejectWithValue("Failed to update user guide");
      }
    } catch (error) {
      return rejectWithValue(error.message || "An unknown error occurred");
    }
  },
);

// Initial State
const initialState = {
  isFileGuideStore: false,
  isMemberGuideStore: false,
};

// Slice
export const userGuideSlice = createSlice({
  name: "userGuide",
  initialState,
  reducers: {
    setFileIntroGuide: (state, action) => {
      state.isFileGuideStore = action.payload;
    },
    setIsMemberUserGuide: (state, action) => {
      state.isMemberUserGuide = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserGuide.fulfilled, (state, action) => {
        if (action.meta.arg.payload === "is_file_guide") {
          state.isFileGuideStore = true;
        } else if (action.meta.arg.payload === "is_member_guide") {
          state.isMemberGuideStore = true;
        }
      })
      .addCase(fetchUserGuide.rejected, (state, action) => {
        console.error("Failed to update user guide:", action.payload);
      });
  },
});

// Actions
export const { setFileIntroGuide, setIsMemberUserGuide } =
  userGuideSlice.actions;

// Reducer
export default userGuideSlice.reducer;
