/*eslint-disable*/
import React, { useState, useEffect } from "react";
import "./ContactUs.scss";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import CustomDragDrop from "../../Common/DragDrop/DragDrop";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import { API_URL, ERR_MSG, HTTP_METHOD, REGEX } from "../../constants";
import { validateMail, validateMessage } from "../../Utils/validationUtils";
import httpRequest from "../../Utils/httpsRequest";
import { useSelector } from "react-redux";
import useLogout from "../../CustomHooks/useLogout";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CustomSelect from "../../Common/Components/CustomSelect/CustomSelect";
import toast from "react-hot-toast";
import { CopyIcon } from "../../Assets/Assets";
import { copy } from "../../Utils/helpers";
function ContactUs() {
  const ref = React.useRef();
  const [contactDetail, setContactDetail] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    category: "",
    message: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [fileList, setFileList] = useState([]);
  const handleLogout = useLogout();
  const { csrfToken } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [selectCategory, setSelectCategory] = useState("");

  useEffect(() => {
    const allFieldsFilled =
      contactDetail.firstName &&
      contactDetail.lastName &&
      contactDetail.emailId &&
      contactDetail.message &&
      selectCategory;
    const noErrors =
      !errors.firstName &&
      !errors.lastName &&
      !errors.emailId &&
      !errors.category &&
      !errors.message;
    setIsFormValid(allFieldsFilled && noErrors);
  }, [contactDetail, errors, fileList, selectCategory]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setContactDetail((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "firstName" || name === "lastName") {
      validateName(name, value);
    } else if (name === "emailId") {
      validateEmail(value);
    } else if (name == "message") {
      validateMessageField(value);
    }
  };

  const validateMessageField = (value) => {
    const { isMessageValid, error } = validateMessage(value, 50);
    setErrors((prev) => ({
      ...prev,
      message: isMessageValid ? "" : error,
    }));
  };

  const validateName = (fieldName, value) => {
    const name = value.trim();
    let error = "";

    if (name.length === 0) {
      error = ERR_MSG.REQUIRED_INPUT;
    } else if (name.length < 3) {
      error = ERR_MSG.SHORT_LENGTH;
    } else if (name.length >= 50) {
      error = ERR_MSG.LONG_LENGTH;
    } else if (!REGEX.STARTS_WITH_LETTER.test(name)) {
      error = ERR_MSG.STARTS_WITH_LETTER;
    } else if (/ {2,}/.test(name)) {
      error = ERR_MSG.CONSECUTIVE_SPACE;
    } else if (!REGEX.NAME.test(name)) {
      error = ERR_MSG.INVALID_NAME;
    }

    setErrors((prev) => ({
      ...prev,
      [fieldName]: error,
    }));
  };

  const validateEmail = (value) => {
    const { isValidMail, error } = validateMail(value);

    setErrors((prev) => ({
      ...prev,
      emailId: isValidMail ? "" : error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("first_name", contactDetail?.firstName);
    formData.append("last_name", contactDetail?.lastName);
    formData.append("email", contactDetail?.emailId);
    formData.append("message", contactDetail?.message);
    formData.append("category", selectCategory);
    setLoading(true);
    const response = await httpRequest(
      API_URL.CONTACT_US,
      HTTP_METHOD.POST,
      formData,
      {
        "X-CSRF-Token": csrfToken,
      },
      null,
      handleLogout,
    );
    setLoading(false);

    if (!response?.error) {
      toast.success(
        "Thank you for contacting us! We'll get back to your message soon.",
      );
      setContactDetail({
        firstName: "",
        lastName: "",
        emailId: "",
        message: "",
      });
      setErrors({
        firstName: "",
        lastName: "",
        emailId: "",
      });
      setFileList([]);
      setSelectCategory("");
      return;
    }
  };

  return (
    <div className="contact">
      <form className="contact_inner" onSubmit={handleSubmit}>
        <h4>Get In Touch</h4>
        <p>We are here for you! How can we help?</p>

        <div className="contact_inner_inputs">
          <InputCustom
            regularInput
            placeholder="Enter First Name"
            label
            labletext="First Name"
            name="firstName"
            required
            minLength={3}
            maxLength={50}
            onChange={handleChange}
            value={contactDetail.firstName}
            error={errors.firstName}
          />
          <InputCustom
            regularInput
            placeholder="Enter Last Name"
            label
            labletext="Last Name"
            name="lastName"
            required
            onChange={handleChange}
            value={contactDetail.lastName}
            error={errors.lastName}
          />
        </div>

        <InputCustom
          regularInput
          placeholder="Enter Your Email ID"
          label
          labletext="Email"
          name="emailId"
          required
          onChange={handleChange}
          value={contactDetail.emailId}
          error={errors.emailId}
          customClass="margin-field"
        />
        <InputCustom
          textareaInput
          placeholder="Type your message..."
          label
          labletext="Message"
          name="message"
          required
          onChange={handleChange}
          value={contactDetail.message}
          customClass="margin-field"
          error={errors.message}
          maxLength={500}
        />
        <CustomSelect
          regularInput
          label
          labelText="Select Category"
          required
          defaultValue={selectCategory}
          value={selectCategory}
          onChange={(value) => {
            setSelectCategory(value);
          }}
          options={[
            { value: "", label: "Select Category" },
            { value: "Spam or Fraud", label: "Spam or Fraud" },
            {
              value: "Disturbing or Inappropriate Content",
              label: "Disturbing or Inappropriate Content",
            },
            {
              value: "Payment Issue",
              label: "Payment Issue",
            },
            { value: "Other Activity", label: "Other Activity" },
          ]}
          customClass="margin-field"
          error={errors.category}
        />

        <CustomDragDrop
          label="Attachments"
          fileList={fileList}
          setFileList={setFileList}
          loading={loading}
        />

        <div className="contact_action">
          <div className="copy-email-container">
            <span className="email-address">feedback@tomi.com</span>
            <span
              className="copy-icon"
              onClick={() => copy("feedback@tomi.com")}
            >
              <CopyIcon />
            </span>
          </div>
          <ButtonCustom
            className={`contact_inner_button m_top20 ${!isFormValid || loading ? "disableBtn" : ""}`}
            label={
              loading ? <Spin indicator={<LoadingOutlined spin />} /> : "Submit"
            }
            regularBtn
            type="submit"
            disabled={!isFormValid || loading}
          />
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
