/*eslint-disable*/
import { Spin, Tabs } from "antd";
import "./FileManagement.scss";
import { useDispatch, useSelector } from "react-redux";
import FileMainTable from "./FileMainTable";
import React, { useEffect, useState } from "react";
import useLogout from "../../CustomHooks/useLogout";
import useDebounce from "../../CustomHooks/useDebounce";
import { ContainerImage, PlusIcon } from "../../Assets/Assets";
import CommonModal from "../../Common/CommonModal/CommonModal";
import {
  createContainer,
  showContainerModal,
  handleDashboardRedirect,
} from "../../store/slices/containerSlice";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import toast from "react-hot-toast";
import useKeyPress from "../../Utils/validationUtils";
import SharedWithMeTable from "../../Pages/ContainersSharedWithMe/SharedWithMeTable.jsx";
import { ERR_MSG, HTTP_STATUS_CODE } from "../../constants/index.js";
import { LoadingOutlined } from "@ant-design/icons";
import { startUserGuide } from "../../Common/UserGuide/UserGuide";
import {
  fetchUserGuide,
  setFileIntroGuide,
} from "../../store/slices/userGuideSlice";

function FileManagement() {
  const dispatch = useDispatch();
  const handleLogOut = useLogout();
  const [searchTerm, setSearchTerm] = useState("");
  const [isBtnDisable, setDisable] = useState(true);
  const [containerName, setContainerName] = useState(null);
  const [containerError, setContainerError] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const { csrfToken, isFileGuide } = useSelector((state) => state.auth);
  // const [isEnterPressed, setIsEnterPressed] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 2000);
  const { containerSharedTabVisible, planType } = useSelector(
    (state) => state.user,
  );
  const { isFileGuideStore } = useSelector((state) => state.userGuide);
  const { containers } = useSelector((state) => state.containers);

  useEffect(() => {
    if (containerError || !containerName || containerName.length < 3) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [containerName, containerError]);

  const handleClearError = () => {
    setContainerError("");
  };
  const showModal = () => {
    setContainerName("");
    setIsModalVisible(true);
  };

  const closeModal = () => {
    handleClearError();
    setIsModalVisible(false);
    handleDashboardRedirect(false);
  };

  const handleChange = (e) => {
    const value = e.target.value.trimStart().replace(/\s{2,}/g, " ");
    setContainerName(value);
    setContainerError("");
    if (value.length > 0 && value.length < 3) {
      setContainerError(ERR_MSG.CONTAINER_MESSAGE);
    } else {
      setContainerError("");
    }
  };

  const handleClick = async () => {
    try {
      if (
        isBtnDisable === false &&
        containerError === "" &&
        loading === false &&
        isModalVisible === true &&
        containerName != ""
      ) {
        setLoading(true);
        setDisable(true);

        const resultAction = await dispatch(
          createContainer({ containerName, handleLogOut, csrfToken }),
        ).unwrap();
        if (resultAction?.code === HTTP_STATUS_CODE.OK) {
          if (containers.length === 0 && !isFileGuide) {
            await dispatch(
              fetchUserGuide({
                payload: "is_file_guide",
                handleLogOut,
                csrfToken,
              }),
            );
          }
          dispatch(setFileIntroGuide(true));

          toast.success(
            resultAction?.message || "Container created successfully!",
          );
        }
      }
      setDisable(false);
      setLoading(false);
    } catch (error) {
      setDisable(false);
      setLoading(false);
      console.error("Error creating container:", error);
    } finally {
      handleDashboardRedirect(false);
      setIsModalVisible(false);
      setLoading(false);
      setDisable(false);
      setContainerName("");
    }
  };

  useEffect(() => {
    if (
      (showContainerModal && isFileGuide) ||
      (showContainerModal && isFileGuideStore)
    ) {
      setIsModalVisible(true);
    }
  }, [showContainerModal, isFileGuide, isFileGuideStore]);

  useKeyPress(
    "Enter",
    () => {
      if (isModalVisible) {
        handleClick();
      }
    },
    [handleClick],
  );

  const handleSearchChange = (e) => {
    if (e && e.target) {
      setSearchTerm(e.target.value);
    } else {
      console.error("Event or target is undefined");
    }
  };

  const tabItems = [
    {
      key: "1",
      label: "My Container",
      children: (
        <FileMainTable
          searchTerm={activeTab === "1" ? debouncedSearchTerm : ""}
          isFileGuide={isFileGuide}
          isFileGuideStore={isFileGuideStore}
        />
      ),
    },
    {
      key: "2",
      label: "Shared with Me",
      children: (
        <SharedWithMeTable
          searchTerm={activeTab === "2" ? debouncedSearchTerm : ""}
        />
      ),
    },
  ];

  useEffect(() => {
    if (containers?.length === 0 && !isFileGuide && !isFileGuideStore) {
      startUserGuide("fileManagement");
    }
  }, [isFileGuide, isFileGuideStore]);

  const OperationsSlot = {
    right: (
      <>
        <InputCustom
          customClass="commonSearchInput"
          label
          searchInputs
          placeholder={
            activeTab === "1"
              ? "Search Container"
              : "Search Files or Containers"
          }
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {activeTab === "1" && (
          <div className="fileManagement_heading_btn">
            <ButtonCustom
              smallBtn
              label="Add Container"
              onClick={showModal}
              lefticon={<PlusIcon />}
              id="create-file-button"
            />
          </div>
        )}
      </>
    ),
  };

  return (
    <div className="fileManagement">
      <div className="">
        <div className="">
          <Tabs
            className="commonTab"
            defaultActiveKey={containerSharedTabVisible ? "2" : "1"}
            items={tabItems}
            onChange={(key) => setActiveTab(key)}
            tabBarExtraContent={OperationsSlot}
          />

          <CommonModal
            visible={isModalVisible}
            title="Create Container"
            className="newContainerModal"
            BtnText={
              loading ? (
                <Spin indicator={<LoadingOutlined spin />} />
              ) : (
                "Continue"
              )
            }
            icon={<ContainerImage />}
            customBtutton
            onClick={handleClick}
            btnDisabled={isBtnDisable}
            content={
              <div>
                <InputCustom
                  regularInput
                  placeholder="Enter container name"
                  value={containerName}
                  label
                  minLength={3}
                  maxLength={50}
                  onChange={handleChange}
                  error={containerError}
                  onClearError={handleClearError}
                  labletext="Name"
                />
              </div>
            }
            onOk={closeModal}
            onCancel={closeModal}
          />
        </div>
      </div>
    </div>
  );
}

export default FileManagement;
