import {
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
} from "@ant-design/icons";
import "./MediaDisplay.scss";
import PropTypes from "prop-types";
import { Image, Space } from "antd";
import { env } from "../../../constants/env";
import { API_URL } from "../../../constants";
import audio from "../../../Assets/images/audio.png";
import React, { useEffect, useState } from "react";
import image from "../../../Assets/images/files.png";
import { viewMedia } from "../../../Utils/helpers";
import useLogout from "../../../CustomHooks/useLogout";
import CommonViewModal from "../../CommonModal/CommonViewModal";
import { useSelector } from "react-redux";

const MediaDisplay = ({
  selectedMedia,
  setModalOpen,
  isPublicDisplay = false,
}) => {
  const handleLogOut = useLogout();
  const [url, setUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };
  const { csrfToken } = useSelector((state) => state.auth);

  const downloadUrl = `${API_URL.DOWNLOAD_FILES}${selectedMedia.shareable_id || selectedMedia.id}/view`;

  const download = async () => {
    setIsLoading(true);
    try {
      const res = await viewMedia(
        downloadUrl,
        selectedMedia.format,
        handleLogOut,
        csrfToken,
      );
      setUrl(res);
    } catch (error) {
      console.error("Error downloading media:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedMedia.is_encrypted) {
      download();
    }
  }, []);

  if (selectedMedia?.format?.includes("image")) {
    return (
      <>
        {isPublicDisplay ? (
          <img
            src={`${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia?.id || selectedMedia?.shareable_id}`}
            alt="Media"
          />
        ) : (
          <Image
            width={200}
            style={{ display: "none" }}
            preview={{
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                    onReset,
                  },
                },
              ) => (
                <Space size={12} className="toolbar-wrapper">
                  <SwapOutlined rotate={90} onClick={onFlipY} />
                  <SwapOutlined onClick={onFlipX} />
                  <RotateLeftOutlined onClick={onRotateLeft} />
                  <RotateRightOutlined onClick={onRotateRight} />
                  <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                  <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                  <UndoOutlined onClick={onReset} />
                </Space>
              ),
              visible: true,
              scaleStep: true,
              src: selectedMedia?.is_encrypted
                ? url
                : `${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia?.shareable_id || selectedMedia?.id}`,
              onVisibleChange: () => closeModal(),
            }}
          />
        )}
      </>
    );
  } else if (selectedMedia?.format?.includes("video")) {
    return (
      <>
        {isPublicDisplay ? (
          <video
            muted
            style={{ width: "100%" }}
            controls
            src={
              url ||
              `${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia?.id || selectedMedia.shareable_id}`
            }
          />
        ) : selectedMedia.is_encrypted ? (
          <CommonViewModal
            visible={true}
            onOk={closeModal}
            onCancel={closeModal}
            content={
              isLoading ? (
                <div className="loading-overlay">
                  <p>Decrypting video...</p>
                </div>
              ) : (
                <video
                  muted
                  style={{ width: "100%" }}
                  controls
                  src={
                    url ||
                    `${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia.shareable_id || selectedMedia?.id}`
                  }
                />
              )
            }
          />
        ) : (
          <CommonViewModal
            visible={true}
            onOk={closeModal}
            onCancel={closeModal}
            content={
              <video
                muted
                style={{ width: "100%" }}
                controls
                src={
                  url ||
                  `${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia.shareable_id || selectedMedia?.id}`
                }
              />
            }
          />
        )}
      </>
    );
  } else if (selectedMedia?.format?.includes("audio")) {
    return (
      <>
        {isPublicDisplay ? (
          <>
            <div className="audioDiv">
              <img src={audio} alt="Audio Thumbnail" />
              <audio
                muted
                controls
                src={
                  url ||
                  `${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia?.id || selectedMedia.shareable_id}`
                }
              />
            </div>
          </>
        ) : (
          <CommonViewModal
            visible={true}
            onOk={closeModal}
            onCancel={closeModal}
            content={
              <div className="audioDiv">
                <img src={audio} alt="Audio Thumbnail" />
                <audio
                  muted
                  controls
                  src={
                    url ||
                    `${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia.shareable_id || selectedMedia?.id}`
                  }
                />
              </div>
            }
          />
        )}
      </>
    );
  } else {
    if (isPublicDisplay) {
      return (
        <div className="unpreviewable">
          <div className="unpreviewable_image">
            <img src={image} alt="" />
          </div>
          <h4>Preview not available for this file type</h4>
        </div>
      );
    }
    if (!selectedMedia.is_encrypted) {
      closeModal();
      window.open(
        url ||
          `${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia?.shareable_id || selectedMedia?.id}`,
        "_blank",
        "noopener",
      );
    }
  }
};

export default MediaDisplay;

MediaDisplay.propTypes = {
  isPublicDisplay: PropTypes.bool,
  selectedMedia: PropTypes.object.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};
