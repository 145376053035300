import React, { useState, useEffect } from "react";
import CommonModal from "../../Common/CommonModal/CommonModal";
import "./ContactUsModal.scss";
import PropTypes from "prop-types";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import { CopyIcon } from "../../Assets/Assets";
import CustomSelect from "../../Common/Components/CustomSelect/CustomSelect";
import CustomDragDrop from "../../Common/DragDrop/DragDrop";
import { copy } from "../../Utils/helpers";
import { ERR_MSG, REGEX } from "../../constants";
import { validateMail, validateMessage } from "../../Utils/validationUtils";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { API_URL, HTTP_METHOD } from "../../constants";
import httpRequest from "../../Utils/httpsRequest";
import toast from "react-hot-toast";
import useLogout from "../../CustomHooks/useLogout";
import { useSelector } from "react-redux";
function ContactUsModal({ show, handleClick, closeModal }) {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleLogout = useLogout();
  const { csrfToken } = useSelector((state) => state.auth);
  const [contactDetails, setContactDetails] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    message: "",
    category: "Payment Issue",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    message: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const allFieldsFilled =
      contactDetails.firstName &&
      contactDetails.lastName &&
      contactDetails.emailId &&
      contactDetails.message;
    // selectCategory;
    const noErrors =
      !errors.firstName &&
      !errors.lastName &&
      !errors.emailId &&
      !errors.message;
    setIsFormValid(
      // allFieldsFilled && noErrors && fileList.length > 0 && captchaToken
      allFieldsFilled && noErrors,
    );
  }, [contactDetails, errors, fileList, closeModal]);

  const validateEmail = (value) => {
    const { isValidMail, error } = validateMail(value);

    setErrors((prev) => ({
      ...prev,
      emailId: isValidMail ? "" : error,
    }));
  };

  const validateMessageField = (value) => {
    const { isMessageValid, error } = validateMessage(value, 50);
    setErrors((prev) => ({
      ...prev,
      message: isMessageValid ? "" : error,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setContactDetails((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "firstName" || name === "lastName") {
      validateName(name, value);
    } else if (name === "emailId") {
      validateEmail(value);
    } else if (name == "message") {
      validateMessageField(value);
    }
  };

  const resetModal = () => {
    setErrors({ firstName: "", lastName: "", emailId: "", message: "" });
    setContactDetails({
      firstName: "",
      lastName: "",
      emailId: "",
      message: "",
      category: "Payment Issue",
    });

    closeModal();
  };

  const validateName = (fieldName, value) => {
    const name = value.trim();
    let error = "";

    if (name.length === 0) {
      error = ERR_MSG.REQUIRED_INPUT;
    } else if (name.length < 3) {
      error = ERR_MSG.SHORT_LENGTH;
    } else if (name.length >= 50) {
      error = ERR_MSG.LONG_LENGTH;
    } else if (!REGEX.STARTS_WITH_LETTER.test(name)) {
      error = ERR_MSG.STARTS_WITH_LETTER;
    } else if (/ {2,}/.test(name)) {
      error = ERR_MSG.CONSECUTIVE_SPACE;
    } else if (!REGEX.NAME.test(name)) {
      error = ERR_MSG.INVALID_NAME;
    }

    setErrors((prev) => ({
      ...prev,
      [fieldName]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("first_name", contactDetails?.firstName);
    formData.append("last_name", contactDetails?.lastName);
    formData.append("email", contactDetails?.emailId);
    formData.append("message", contactDetails?.message);
    formData.append("category", contactDetails?.category);
    // formData.append("captchaToken", captchaToken ? captchaToken : "");
    setLoading(true);
    const response = await httpRequest(
      API_URL.CONTACT_US,
      HTTP_METHOD.POST,
      formData,
      {
        "X-CSRF-Token": csrfToken,
      },
      null,
      handleLogout,
    );
    setLoading(false);

    if (!response?.error) {
      toast.success(
        "Thank you for contacting us! We'll get back to your message soon.",
      );
      setContactDetails({
        firstName: "",
        lastName: "",
        emailId: "",
        message: "",
        category: "Payment Issue",
      });
      setErrors({
        firstName: "",
        lastName: "",
        emailId: "",
        message: "",
      });
      setFileList([]);
      closeModal();

      return;
    }
  };
  return (
    <>
      <CommonModal
        className="reportModal"
        visible={show}
        onClick={handleClick}
        content={
          <>
            <form className="reportModal_inner">
              <h4>Contact Us</h4>
              <p>We are here for you! How can we help?</p>

              <div className="reportModal_inner_inputs">
                <InputCustom
                  regularInput
                  placeholder="Enter First Name"
                  label
                  labletext="First Name"
                  name="firstName"
                  required
                  value={contactDetails?.firstName}
                  minLength={3}
                  maxLength={50}
                  onChange={handleChange}
                  error={errors.firstName}
                />
                <InputCustom
                  regularInput
                  placeholder="Enter Last Name"
                  label
                  value={contactDetails?.lastName}
                  labletext="Last Name"
                  name="lastName"
                  required
                  minLength={3}
                  maxLength={50}
                  onChange={handleChange}
                  error={errors.lastName}
                />
              </div>

              <InputCustom
                regularInput
                placeholder="Enter Your Email ID"
                label
                labletext="Email"
                value={contactDetails?.emailId}
                name="emailId"
                required
                customClass="margin-field"
                onChange={handleChange}
                error={errors.emailId}
              />

              <InputCustom
                textareaInput
                placeholder="Type your message...."
                label
                labletext="Message"
                name="message"
                required
                value={contactDetails.message}
                customClass="margin-field"
                onChange={handleChange}
                error={errors.message}
                maxLength={500}
              />
              <CustomSelect
                regularInput
                label
                disabled
                labelText="Select Category"
                required
                defaultValue="Payment Issue"
                options={[
                  {
                    value: "Payment Issue",
                    label: "Payment Issue",
                    disabled: true,
                  },
                ]}
                customClass="margin-field"
              />
              <CustomDragDrop
                label="Attachments"
                fileList={fileList}
                setFileList={setFileList}
                loading={loading}
              />

              <div className="bottom_action">
                <h3>
                  feedback@tomi.com
                  <span onClick={() => copy("feedback@tomi.com")}>
                    <CopyIcon />
                  </span>
                </h3>
                <ButtonCustom
                  className={`contact_inner_button m_top20 ${!isFormValid || loading ? "disableBtn" : ""}`}
                  onClick={handleSubmit}
                  label={
                    loading ? (
                      <Spin indicator={<LoadingOutlined spin />} />
                    ) : (
                      "Submit"
                    )
                  }
                  regularBtn
                  type="submit"
                  disabled={!isFormValid || loading}
                />
              </div>
            </form>
          </>
        }
        onOk={closeModal}
        onCancel={resetModal}
      />
    </>
  );
}

export default ContactUsModal;
ContactUsModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  handleClick: PropTypes.func,
  mediaId: PropTypes.any,
  setShow: PropTypes.func,
  contactDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    emailId: PropTypes.string,
    message: PropTypes.string,
  }),
  setContactDetails: PropTypes.func,
};
