import {
  setMainLoading,
  setWltSignature,
} from "../store/slices/uiSettingSlice";
import introJs from "intro.js";
import { useDispatch } from "react-redux";
import { removeCookie } from "../Utils/helpers";
import { useDisconnect } from "@web3modal/ethers/react";
import { clearShareState } from "../store/slices/shareSlice";
import { logOut, setWalletAddress } from "../store/slices/authSlice";
import { clearContainerState } from "../store/slices/containerSlice";
import { clearUserData, setPaymentPlan } from "../store/slices/userSlice";
import localStorageUtil from "../Utils/localStorage";
import { setTheme } from "../store/slices/themeSlice";

export default function useLogout() {
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const handleLogout = async () => {
    try {
      localStorage.clear("setUserIntroGuide");
      localStorage.clear("isReloaded");
      introJs().exit();
      dispatch(logOut());
      localStorageUtil.clear();
      dispatch(clearUserData());
      dispatch(clearShareState());
      dispatch(setPaymentPlan(null));
      dispatch(setWltSignature(null));
      dispatch(clearContainerState());
      dispatch(setWalletAddress(null));
      dispatch(setMainLoading(false));
      dispatch(setTheme("light"));
      removeCookie("tomi-token", "/");
      await disconnect();
      return true;
    } catch (error) {
      console.log("error while logout", error);
      return false;
    }
  };

  return handleLogout;
}
